import "./App.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import { Home } from "./pages/Home";
import { Main } from "./pages/Main";
import { SignUp } from "./pages/SignUp";
import { Login } from "./pages/Login";
import { Contact } from "./pages/Contact";
import { HeaderTest } from "./pages/HeaderTest";
import { Auth } from "./pages/Auth";
import { Dashboard } from "./pages/dashboard";
import { ProjectDetail } from "./pages/dashboard/project-detail";
import { ChooseService } from "./pages/ChooseService";
import { Faq } from "./pages/faq";
import { Privacy } from "./pages/Privacy";
import { Terms } from "./pages/Terms";
import { UserSubsscription } from "./pages/UserSubsscription";
import { Receipt } from "./pages/Receipt";
import Stripe from "./pages/Stripe";
import { Questionaire } from "./pages/Questionaire";
import Admin from "./pages/Admin";
import { UserDetail } from "./pages/user-detail";
import AllUsers from "./pages/Admin/all-users";
import { AllProjects } from "./pages/Admin/all-projects";

function App() {
  return (
    <BrowserRouter>
      <div className="body">
        <Switch>
          <Route exact path="/" component={Main} />
          {/* <Route exact path="/" component={Home} /> */}
          {/* <Route exact path="/signup" component={SignUp} /> */}
          <Route exact path="/questionaire" component={Questionaire} />
          {/* <Route exact path="/login" component={Login} /> */}
          <Route exact path="/auth" component={Auth} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/headerTest" component={HeaderTest} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/choose" component={ChooseService} />
          <Route exact path="/subscription" component={UserSubsscription} />
          <Route exact path="/receipt/:id" component={Receipt} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/project/:id" component={ProjectDetail} />
          <Route exact path="/user-detail/:id" component={UserDetail} />
          <Route exact path="/admin-users" component={AllUsers} />
          <Route exact path="/admin-projects" component={AllProjects} />
          <Route exact path="/stripe" component={Stripe} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
