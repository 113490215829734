import React from "react";
import logo from "../assets/img/logo.png";

export const Footer = () => {
  return (
    <footer class="position-relative" id="footer-main">
      <div class="footer pt-lg-7">
        {/* <div class="shape-container shape-line shape-position-top shape-orientation-inverse"></div> */}
        <div class="container pt-4">
          <div class="row">
            <div class="col-lg-5 mb-5 mb-lg-0">
              <a href="/" className="mr-4">
                <img src={logo} alt="" />
              </a>
              <p class="mt-4 text-sm opacity-8 pr-lg-4">
                We are a team of entrepreneurs, comprised of skilled and
                professional individuals throughout the world with our
                headquarters located in Ontario, Canada.
              </p>
              <ul class="nav mt-4">
                <li class="nav-item">
                  <a
                    class="nav-link pl-0"
                    href="https://instagram.com/solnaryltd"
                    target="_blank"
                  >
                    <span
                      class="iconify"
                      data-icon="ant-design:instagram-outlined"
                      data-inline="false"
                    ></span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link pl-0"
                    href="https://facebook.com/solnaryltd/"
                    target="_blank"
                  >
                    <span
                      class="iconify"
                      data-icon="jam:facebook-circle"
                      data-inline="false"
                    ></span>
                  </a>
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link pl-0"
                    href="https://dribbble.com/solnary"
                    target="_blank"
                  >
                    <span
                      class="iconify"
                      data-icon="ph:dribbble-logo"
                      data-inline="false"
                    ></span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 ml-lg-auto mb-5 mb-lg-0">
              <h6 class="mb-3">Services</h6>
              <ul class="list-unstyled">
                <li>
                  <a className={"click"} href="#plans">
                    Management
                  </a>
                </li>
                <li>
                  <a className={"click"} href="#plans">
                    Web
                  </a>
                </li>
                <li>
                  <a className={"click"} href="#plans">
                    Mobile
                  </a>
                </li>
                <li>
                  <a className={"click"} href="#plans">
                    ui/ux Designs
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
              <h6 class="heading mb-3">About</h6>
              <ul class="list-unstyled">
                <li>
                  <a href="/#tech">Our Mission</a>
                </li>
                <li>
                  <a href="/#portfolio">Portfolio</a>
                </li>
                <li>
                  <a href="#">Support</a>
                </li>
                {/* <li>
                  <a href="#">Careers</a>
                </li> */}
              </ul>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
              <h6 class="heading mb-3">Company</h6>
              <ul class="list-unstyled">
                <li>
                  <a href="/signup">Join us</a>
                </li>
                <li>
                  <a href="/faq">Faq</a>
                </li>

                <li>
                  <a href="/#plans">Plans</a>
                </li>
              </ul>
            </div>
          </div>

          <hr class="solid" />

          <div class="divider divider-fade divider-dark mt-4">
            <div class="row align-items-center justify-content-md-between">
              <div class="col-md-6">
                <div class="text-center text-md-left">
                  <p>&copy; 2021 Solnary. All rights reserved</p>
                </div>
              </div>
              <div class="col-md-6">
                <ul class="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                  <li class="nav-item">
                    <a class="nav-link" href="/terms">
                      Terms
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/privacy">
                      Privacy
                    </a>
                  </li>
                  {/* <li class="nav-item">
                    <a class="nav-link" href="#">
                      Cookies
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
