import React, { useState } from "react";
import { useHistory } from "react-router";

export const PlanSection = () => {
  const history = useHistory();
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);
  const [show4, setshow4] = useState(false);

  return (
    <div>
      <section id="plans">
        <div className="container my-5">
          <h3 className="font-bold mt-5 mb-2 text-white">
            Simple, transparent pricing
          </h3>
          <p>No contracts, No surprise fee</p>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-6 my-3">
              <div className="price-wrapper p-4">
                <div>
                  <h5 className="font-bold">
                    PACKAGE A <br />
                    (Technical website audit)
                  </h5>
                  <div className="price">
                    <span className="tag">$149</span>
                    <span className="duration">/Month</span>
                  </div>
                  <div className="my-2">
                    Industry leading tools and strategies that boost website
                    traffic and conversions.
                  </div>
                  <ul>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Website crawl
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Broken links assessment and correction
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Inbound link assessment and cleanup
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Image tag assessment
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      XML sitemap creation
                    </li>
                    <li
                      className={`view-more ${show1 ? "d-none" : ""}`}
                      onClick={() => {
                        setshow4(false);
                        setshow2(false);
                        setshow1(true);
                      }}
                    >
                      View more
                    </li>
                  </ul>
                  <ul className={`more-content ${show1 ? "show" : ""}`}>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      URL restructuring
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Targeted Business Listings Optimization & Creation
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Load Time Optimization
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Plugin Maintenance
                    </li>
                  </ul>
                </div>
                <button
                  className="btn btn-plan my-4 mt-auto"
                  onClick={() => {
                    history.push({
                      pathname: `/stripe`,
                      state: { plan: "A", price: "149" },
                    });
                  }}
                >
                  Choose Plan
                </button>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-6 my-3">
              <div className="price-wrapper p-4">
                <div>
                  <h5 className="font-bold">
                    PACKAGE B <br />
                    (Market and keyword analysis)
                  </h5>
                  <div className="price">
                    <span className="tag">$299</span>
                    <span className="duration">/Month</span>
                  </div>
                  <ul>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      PACKAGE A
                    </li>

                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Keyword analysis
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Keyword Optimization
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Content guidance
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Mobile Optimization
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Google My Business Setup & Optimization
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Basic Local Posts Setup
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Special Attributes Setup
                    </li>
                    <li
                      className={`view-more ${show2 ? "d-none" : ""}`}
                      onClick={() => {
                        setshow1(false);
                        setshow4(false);
                        setshow2(true);
                      }}
                    >
                      View more
                    </li>
                  </ul>
                  <ul className={`more-content ${show2 ? "show" : ""}`}>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Yelp & Local Listings Optimization
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Google Maps Optimization
                    </li>
                  </ul>
                </div>
                <button
                  className="btn btn-plan my-4 mt-auto"
                  onClick={() => {
                    history.push({
                      pathname: `/stripe`,
                      state: { plan: "B", price: "299" },
                    });
                  }}
                >
                  Choose Plan
                </button>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-6 my-3">
              <div className="price-wrapper p-4 bg-color">
                <div>
                  <h5 className="font-bold">
                    PACKAGE C <br />
                    {"(SEO & Lead Generation)"}
                  </h5>
                  <div className="price">
                    <span className="tag">$499</span>
                    <span className="duration">/Month</span>
                  </div>
                  <ul>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      {"PACKAGE A&B"}
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Optimize current pages
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Add additional pages
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Taxonomy optimization
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Mobile optimization
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Optimizing videos for search engines
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Optimizing images for search engines (Image SEO)
                    </li>
                  </ul>
                </div>
                <button
                  className="btn btn-plan my-4 mt-auto"
                  onClick={() => {
                    history.push({
                      pathname: `/stripe`,
                      state: { plan: "C", price: "499" },
                    });
                  }}
                >
                  Choose Plan
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 my-3">
              <div className="price-wrapper p-4">
                <div>
                  <h5 className="font-bold">(PAID MARKETING)</h5>
                  <div className="price">
                    <span className="tagC">Custom Price</span>
                    {/* <span className="duration">/Month</span> */}
                  </div>
                  <div className="my-2">
                    For most of the buiness that want to optimize web queries
                  </div>
                  <ul>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Drive traffic through paid search
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Reduce search engine marketing costs and CPC
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Dominate search pages
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Drive leads and/or sales
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Copy creation
                    </li>
                    <li
                      className={`view-more ${show4 ? "d-none" : ""}`}
                      onClick={() => {
                        setshow1(false);
                        setshow2(false);
                        setshow4(true);
                      }}
                    >
                      View more
                    </li>
                  </ul>
                  <ul className={`more-content ${show4 ? "show" : ""}`}>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Achieve synergies between your paid search and natural
                      (organic) search programs
                    </li>

                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      UTM tracking for Adcenter campaigns
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Competitive analysis
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Keyword research and development
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Google Analytics tags
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Bid management
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Site selection for Google content network
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Campaign structure
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Landing page selection
                    </li>
                    <li>
                      <span className="mr-2">
                        <span
                          class="iconify"
                          data-icon="bx:bxs-check-shield"
                          data-inline="false"
                        ></span>
                      </span>
                      Conversion tracking set-up
                    </li>
                  </ul>
                </div>
                <button
                  className="btn btn-plan my-4 mt-auto"
                  onClick={() => {
                    history.push(`/contact`);
                  }}
                >
                  Contact Sales
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
