import firebase from "firebase";
// import AddAccount from "../components/addAccount";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { Sidebar } from "../../components/Sidebar";
import { getuserData } from "../../helpers/fireRequests";
import "./styles.scss";

export default function Admin(props) {
  const history = useHistory();

  const [allUsers, setAllUsers] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [todaysBooking, setTodaysBooking] = useState({});
  const [weekBooking, setWeekBooking] = useState(0);
  const [monthBooking, setMonthBooking] = useState({});
  const [allTransactionAmount, setAlltransactionAmount] = useState(0);
  const [todaysUser, setTodaysUser] = useState(0);
  const [weekUser, setWeekUser] = useState(0);
  const [monthUser, setMonthUser] = useState(0);

  useEffect(() => {
    getProjects();
    getUsers();
  }, []);

  const getUsers = () => {
    setLoading(true);
    let userItem = [];
    let todaysCount = 0;
    let weekCount = 0;
    let monthCount = 0;

    firebase
      .firestore()
      .collection("users")
      // .where("accountType", "==", IDS.CLIENT)
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.empty) {
          toast("Error fetching users");
          return;
        }

        userItem = [];

        querySnapshot.forEach((doc) => {
          var itemVal = doc.data();
          itemVal.id = doc.id;

          if (isInToday(itemVal.created_at)) {
            todaysCount++;
          }

          if (isInWeek(itemVal.created_at)) {
            weekCount++;
          }

          if (isInMonth(itemVal.created_at)) {
            monthCount++;
          }

          userItem.push(itemVal);
        });

        userItem.sort((a, b) =>
          b.created_at > a.created_at ? 1 : a.created_at > b.created_at ? -1 : 0
        );

        setAllUsers(userItem);
        setTodaysUser(todaysCount);
        setWeekUser(weekCount);
        setMonthUser(monthCount);
        setLoading(false);
      })
      .catch((error) => {
        toast("Error getting user data");
        console.log("Error getting user data: ", error);
      });
  };

  const getProjects = () => {
    setLoading(true);
    let orderItem = [];
    let todaysCount = 0;
    let weekCount = 0;
    let monthCount = 0;
    let todaysTransactionAmount = 0;
    let weekTransactionAmount = 0;
    let monthTransactionAmount = 0;
    let allTransactionTotal = 0;

    firebase
      .firestore()
      .collection("projects")
      .get()
      .then(async function (querySnapshot) {
        if (querySnapshot.empty) {
          toast("There are no user");
          return;
        }

        let itemsProcessed = 0;

        orderItem = [];

        querySnapshot.forEach(async (doc, id, array) => {
          var itemVal = doc.data();

          itemVal.id = doc.id;

          if (isInToday(itemVal.createdAt)) {
            todaysCount++;
            todaysTransactionAmount =
              parseFloat(todaysTransactionAmount) + parseFloat(itemVal.cost);
            todaysTransactionAmount = todaysTransactionAmount.toFixed(2);
          }

          if (isInWeek(itemVal.createdAt)) {
            // console.log(itemVal.createdAt);
            weekCount++;
            weekTransactionAmount =
              parseFloat(weekTransactionAmount) + parseFloat(itemVal.cost);
            weekTransactionAmount = weekTransactionAmount.toFixed(2);
          }

          if (isInMonth(itemVal.createdAt)) {
            monthCount++;
            monthTransactionAmount =
              parseFloat(monthTransactionAmount) + parseFloat(itemVal.cost);
            monthTransactionAmount = monthTransactionAmount.toFixed(2);
          }

          if (itemVal.cost && itemVal.cost != "") {
            allTransactionTotal =
              parseFloat(allTransactionTotal) + parseFloat(itemVal.cost);
            allTransactionTotal = allTransactionTotal.toFixed(2);

            setAlltransactionAmount(allTransactionTotal);

            // console.log(allTransactionAmount);
          }

          itemVal.createdAt = getDateFromStamp(itemVal.createdAt);

          let user = await getuserData(itemVal.userId).catch((error) => {
            // reject(error);
          });

          itemsProcessed++;

          orderItem.push({ ...itemVal, user: user });

          if (itemsProcessed === querySnapshot.size) {
            orderItem.sort((a, b) =>
              b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
            );

            console.log("PRODUCT", orderItem);
            setAllOrders(orderItem);

            setTodaysBooking({ todaysCount, todaysTransactionAmount });
            setWeekBooking({ weekCount, weekTransactionAmount });
            setMonthBooking({ monthCount, monthTransactionAmount });
            setLoading(false);
          }
        });
      })
      .catch((error) => {
        toast("Error getting order data");
        console.log("Error getting user data: ", error);
      });
  };

  // get today
  const isInToday = (ts) => {
    // let today = new Date();
    let today = new Date().setHours(0, 0, 0, 0);
    var thatDay = new Date(ts).setHours(0, 0, 0, 0);

    if (today === thatDay) {
      return true;
    } else return false;
  };

  // get weeks
  const isInWeek = (ts) => {
    return moment(new Date(ts)).isSame(new Date(), "week"); //true if dates are in the same month
  };

  // get months
  const isInMonth = (ts) => {
    return moment(new Date(ts)).isSame(new Date(), "month"); //true if dates are in the same month
  };

  const getDateFromStamp = (timestamp) => {
    return new Date(parseInt(timestamp)).toDateString();
  };

  const viewProfile = (user) => {
    history.push({
      pathname: `/user-detail/${user.id}`,
      state: { user },
    });
  };

  const viewProject = (project) => {
    history.push({
      pathname: `/project/${project.business_name}`,
      state: { project },
    });
  };

  // const viewUserDetails = (id) => {
  //   props.history.push({ pathname: `/userDetail/${id}`, state: { id } });
  // };

  if (loading) {
    return (
      <div className="admin-wrapper content pt-4 pl-4 pr-4">
        <SkeletonTheme color="#050d21" highlightColor="#0b1c45">
          <div className="row my-3">
            <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
              <Skeleton color="red" height={100} />
            </div>
            <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
              <Skeleton height={100} />
            </div>
            <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
              <Skeleton height={100} />
            </div>
            <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
              <Skeleton height={100} />
            </div>
          </div>
          <Skeleton count={3} height={70} />
          <div className="row mt-3">
            <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
              <Skeleton height={100} />
            </div>
            <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
              <Skeleton height={100} />
            </div>
            <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
              <Skeleton height={100} />
            </div>
            <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
              <Skeleton height={100} />
            </div>
          </div>
        </SkeletonTheme>
        ;
      </div>
    );
  }

  return (
    <div className="admin-wrapper">
      <ToastContainer />
      <Navbar />
      <Sidebar />

      <div className="container content">
        <div className="row mt-8">
          <div className="col-lg-4 col-sm-12">
            <h2 className="title cl-text text-bold">Projects</h2>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="pills-today-tab"
                  data-toggle="pill"
                  href="#pills-today"
                  role="tab"
                  aria-controls="pills-today"
                  aria-selected="true"
                >
                  Today <br />{" "}
                  <div className="small-line text-center mt-1"></div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-week-tab"
                  data-toggle="pill"
                  href="#pills-week"
                  role="tab"
                  aria-controls="pills-week"
                  aria-selected="false"
                >
                  Week <br />{" "}
                  <div className="small-line text-center mt-1"></div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-month-tab"
                  data-toggle="pill"
                  href="#pills-month"
                  role="tab"
                  aria-controls="pills-month"
                  aria-selected="false"
                >
                  Month <br />{" "}
                  <div className="small-line text-center mt-1"></div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-allTime-tab"
                  data-toggle="pill"
                  href="#pills-allTime"
                  role="tab"
                  aria-controls="pills-allTime"
                  aria-selected="false"
                >
                  All Time <br />{" "}
                  <div className="small-line text-center mt-1"></div>
                </a>
              </li>
            </ul>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-today"
                role="tabpanel"
                aria-labelledby="pills-today-tab"
              >
                <div className="card-alt p-4">
                  <h4 className="cl-text text-bold font12">Today</h4>
                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="text-grey font12">Incoming</div>
                      <div className="cl-text font36 line-height text-bold mt-1">
                        {todaysBooking.todaysCount}
                      </div>
                    </div>
                  </div>
                  <p className="text-grey font12 pt-4">
                    {"Transaction Value: CAD " +
                      todaysBooking.todaysTransactionAmount}
                  </p>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="pills-week"
                role="tabpanel"
                aria-labelledby="pills-week-tab"
              >
                <div className="card-alt p-4">
                  <h4 className="cl-text text-bold font12">Week</h4>
                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="text-grey font12">Incoming</div>
                      <div className="cl-text font36 line-height text-bold mt-1">
                        {weekBooking.weekCount}
                      </div>
                    </div>
                  </div>
                  <p className="text-grey font12 pt-4">
                    Transaction Value: CAD {weekBooking.weekTransactionAmount}
                  </p>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="pills-month"
                role="tabpanel"
                aria-labelledby="pills-month-tab"
              >
                <div className="card-alt p-4">
                  <h4 className="cl-text text-bold font12">Month</h4>
                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="text-grey font12">Incoming</div>
                      <div className="cl-text font36 line-height text-bold mt-1">
                        {monthBooking.monthCount}
                      </div>
                    </div>
                  </div>
                  <p className="text-grey font12 pt-4">
                    Transaction Value: CAD {monthBooking.monthTransactionAmount}
                  </p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-allTime"
                role="tabpanel"
                aria-labelledby="pills-allTime-tab"
              >
                <div className="card-alt p-4">
                  <h4 className="cl-text text-bold font12">All Time</h4>
                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="text-grey font12">Incoming</div>
                      <div className="cl-text font36 line-height text-bold mt-1">
                        {allOrders.length}
                      </div>
                    </div>
                  </div>
                  <p className="text-grey font12 pt-4">
                    Transaction Value: CAD {allTransactionAmount}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 sm-mt">
            <h2 className="title cl-text text-bold">{"Users"}</h2>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="pills-Today-tab"
                  data-toggle="pill"
                  href="#pills-Today"
                  role="tab"
                  aria-controls="pills-Today"
                  aria-selected="true"
                >
                  Today <br />{" "}
                  <div className="small-line text-center mt-1"></div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-Week-tab"
                  data-toggle="pill"
                  href="#pills-Week"
                  role="tab"
                  aria-controls="pills-Week"
                  aria-selected="false"
                >
                  Week <br />{" "}
                  <div className="small-line text-center mt-1"></div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-Month-tab"
                  data-toggle="pill"
                  href="#pills-Month"
                  role="tab"
                  aria-controls="pills-Month"
                  aria-selected="false"
                >
                  Month <br />{" "}
                  <div className="small-line text-center mt-1"></div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-AllTime-tab"
                  data-toggle="pill"
                  href="#pills-AllTime"
                  role="tab"
                  aria-controls="pills-AllTime"
                  aria-selected="false"
                >
                  All Time <br />{" "}
                  <div className="small-line text-center mt-1"></div>
                </a>
              </li>
            </ul>
            <div className="tab-content workers-tab" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-Today"
                role="tabpanel"
                aria-labelledby="pills-Today-tab"
              >
                <div className="card-alt p-4">
                  <h4 className="cl-text text-bold font12">Today</h4>
                  <div className="mt-3">
                    <div className="text-grey font12">Incoming</div>
                    <div className="cl-text font36 line-height text-bold mt-0">
                      {todaysUser}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-Week"
                role="tabpanel"
                aria-labelledby="pills-Week-tab"
              >
                <div className="card-alt p-4">
                  <h4 className="cl-text text-bold font12">Week</h4>
                  <div className="mt-3">
                    <div className="text-grey font12">Incoming</div>
                    <div className="cl-text font36 line-height text-bold mt-0">
                      {weekUser}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-Month"
                role="tabpanel"
                aria-labelledby="pills-Month-tab"
              >
                <div className="card-alt p-4">
                  <h4 className="cl-text text-bold font12">Month</h4>
                  <div className="mt-3">
                    <div className="text-grey font12">Incoming</div>
                    <div className="cl-text font36 line-height text-bold mt-0">
                      {monthUser}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-AllTime"
                role="tabpanel"
                aria-labelledby="pills-AllTime-tab"
              >
                <div className="card-alt p-4">
                  <h4 className="cl-text text-bold font12">All Time</h4>
                  <div className="mt-3">
                    <div className="text-grey font12">Incoming</div>
                    <div className="cl-text font36 line-height text-bold mt-0">
                      {allUsers.length}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 sm-mt-4">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-todays"
                role="tabpanel"
                aria-labelledby="pills-todays-tab"
              >
                <div className="card p-4 text-white bg-red text-bold">
                  <h4 className="text-bold font12">All Time Projects Value</h4>
                  <p className="font36">CAD {allTransactionAmount}</p>
                </div>
                <div className="card p-4 text-white bg-red text-bold mt-4">
                  <h4 className="text-bold font12">All Time Users</h4>
                  <div className="row mt-3">
                    <div className="col-7">
                      <p className="font36">{allUsers.length}</p>
                    </div>
                    {/* <div className="col-5 my-auto">
                      <a
                        href="#"
                        className="btn small-btn bg-white text-grey font12"
                        id="myModal"
                        type="button"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        Add User
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal --> */}
        {/* <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <AddAccount />
          </div>
        </div> */}
        <div className="mt-8vh">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="card-alt pl-3 pr-2">
                <div className="d-flex pt-4 px-2 align-items-center">
                  <p className={"font20 text-bold text-grey fill"}>
                    Recent Projects
                  </p>
                  <div
                    className={"d-flex click"}
                    onClick={() => {
                      history.push("admin-projects");
                    }}
                  >
                    <h6 className={"status text-grey font12"}>{"View All"}</h6>
                  </div>
                </div>
                <div className="table-responsive">
                  <table
                    id="example-2"
                    className="table table-borderless text-nowrap w-100"
                  >
                    <thead>
                      <tr>
                        <th className="font12 text-dark-grey">Name</th>
                        <th className="font12 text-dark-grey">Progress</th>
                        <th className="font12 text-dark-grey">Status</th>
                        <th className="font12 text-dark-grey">Date Created</th>
                      </tr>
                    </thead>
                    {allOrders.length > 0 && (
                      <tbody>
                        {allOrders.slice(0, 5).map((booking, index) => (
                          <tr
                            className="my-3"
                            key={booking.id}
                            onClick={() => viewProject(booking)}
                          >
                            <td>
                              <div className="font14 text-grey text-bold">
                                {booking.user.name}
                              </div>
                            </td>
                            <td>
                              <div className="font14 text-grey text-bold">
                                {booking.progress || "-"}
                              </div>
                            </td>
                            <td>
                              <div
                                className={
                                  booking.status == "Cancelled"
                                    ? "red-badge badge font9"
                                    : booking.status == "Pending"
                                    ? "pending-badge badge font9"
                                    : "confirm-badge badge font9"
                                }
                              >
                                {booking.status}
                              </div>
                            </td>
                            <td>
                              <div className="font14 text-grey">
                                {booking.createdAt}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 sm-mt">
              <div className="card-alt pl-3 pr-2">
                {/* <tr> */}
                {/* <th> */}

                <div className="d-flex pt-4 px-2 align-items-center">
                  <p className={"font20 text-bold text-grey fill"}>
                    Recent Users
                  </p>

                  <div
                    className={"d-flex"}
                    onClick={() => {
                      history.push("admin-users");
                    }}
                  >
                    <h6 className={"status text-grey font12 click"}>{"View All"}</h6>
                  </div>
                </div>
                {/* </th> */}
                {/* </tr> */}

                <div className="table-responsive">
                  <table
                    id="example-2"
                    className="table table-borderless text-nowrap w-100"
                  >
                    <thead>
                      <tr>
                        <th className="font12 text-dark-grey">Name</th>
                        <th className="font12 text-dark-grey">Email</th>
                        <th className="font12 text-dark-grey">Joined</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allUsers.slice(0, 5).map((post, index) => (
                        <tr
                          key={post.id + index}
                          onClick={() => viewProfile(post)}
                        >
                          <td>
                            <div className="font14 text-grey text-bold">
                              {post.name}
                            </div>
                          </td>
                          <td>
                            <div className="font14 text-grey text-bold">
                              {post.email}
                            </div>
                          </td>
                          <td>
                            <div className="font14 text-grey text-bold">
                              {getDateFromStamp(post.createdAt)}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer className="mt-auto" />
    </div>
  );
}
