import React from "react";

export const Footer = () => {
  return (
    <footer>
      <div className="">
        <div className="d-flex flex-wrap mb-3">
          <ul class="nav mt-0">
            <li class="nav-item">
              <a
                class="nav-link pl-0"
                href="https://instagram.com/solnaryltd"
                target="_blank"
              >
                <span
                  class="iconify"
                  data-icon="ant-design:instagram-outlined"
                  data-inline="false"
                ></span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link pl-0"
                href="https://facebook.com/solnaryltd/"
                target="_blank"
              >
                <span
                  class="iconify"
                  data-icon="jam:facebook-circle"
                  data-inline="false"
                ></span>
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link pl-0"
                href="https://dribbble.com/solnary"
                target="_blank"
              >
                <span
                  class="iconify"
                  data-icon="ph:dribbble-logo"
                  data-inline="false"
                ></span>
              </a>
            </li>
          </ul>
        </div>
        <p className="rights">
          © 2021 Solnary Global IT Solutions. All Rights Reserved.
        </p>
        {/* <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h4>Solnary</h4>
            <p>
              Your web project deserves a creative team like Solnary, we will
              tackle obstacles and finish complex tasks with efficiency!
            </p>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-6">
                <h3>Friend Us</h3>
                <a href="https://www.facebook.com/solnary">Facebook</a> <br />
                <a href="https://www.instagram.com/solnary/">Instagram</a>
              </div>
              <div className="col-lg-6 col-6">
                <h3>Company</h3>
                <a href="/">Privacy policy</a>
                <br />
                <a href="/">Term of services</a>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </footer>
  );
};
