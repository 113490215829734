import { Form, Input, Checkbox, Radio } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Footer } from "../../components/FooterV";
import { Navbar } from "../../components/Navbar";
import { Sidebar } from "../../components/Sidebar";
import { useDropzone } from "react-dropzone";
import ProgressBar from "@ramonak/react-progress-bar";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import ReactLoading from "react-loading";
import appConfig from "../../config/fire";
// import "antd/dist/antd.css";
import "./styles.scss";
import { useCallback } from "react";
import { WEBSITES } from "../../config/constant";
import { useRef } from "react";

const seoNodes = [
  "Bring in new clients to your business",
  "Sell a product or products online",
];

export const Questionaire = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const plan = location.state?.plan || WEBSITES;

  const [section, setSection] = useState(0);
  const [files, setFiles] = useState([]);
  const [proposeSEO, setProposeSEO] = useState(0);
  const [purposeCheckList, setPurposeCheckList] = useState([]);
  const [loading, setloading] = useState(false);

  const [decisionMakers, setDecisionMakers] = useState(1);

  const checkInput = (form_id) => {
    return form.getFieldValue(form_id);
  };

  const [formData, setFormData] = useState({});
  const formDataRef = React.useRef(formData);

  const handleFormData = (stateObj) => {
    setFormData((prevState) => {
      formDataRef.current = {
        ...prevState,
        ...stateObj,
      };

      return { ...prevState, ...stateObj };
    });
  };

  const btnClick = () => {
    handleFormState();

    if (section == 2) {
      if (!checkInput("business_name") || !checkInput("business_email")) {
        onNextFailed();
        return;
      }
    }

    if (section == 3) {
      seoNodes.map((element) => {
        if (purposeCheckList.includes(element)) {
          setProposeSEO(true);
        }
      });

      if (!checkInput("deadline")) {
        onNextFailed();
        return;
      }
    }

    if (section == 6) {
      if (!proposeSEO) {
        onFinish();
        return;
      }
    }
    if (section == 10) {
      onFinish();
      return;
    }
    setSection(section + 1);
  };

  const onFinish = () => {
    setloading(true);

    var uid = appConfig.firebase.auth().currentUser.uid;

    handleFormData({
      createdAt: Date.now(),
      userId: uid,
      projectCategory: plan,
      status: "NEW",
    });

    setTimeout(() => {
      let currentData = formDataRef.current;

      for (var prop in currentData) {
        if (currentData[prop] === undefined) {
          currentData[prop] = "";
        }
      }

      console.log("____FINAL_DATA____", currentData);
      //TODO Upload files

      appConfig.firebase
        .firestore()
        .collection("projects")
        .add(currentData)
        .then((snapshot) => {
          setloading(false);
          history.push("/dashboard");
        })
        .catch((error) => {
          setloading(false);
          toast(error);
          console.log(error);
        });
    }, 2500);
  };

  //   const handleFireBaseUpload = () => {
  //   console.log("start of upload");
  //   if (productImgPath.path === "") {
  //     toast("Please select product image");
  //     // console.error(`not an image, the image file is a ${typeof imageAsFile}`);
  //     return;
  //   }

  //   setLoading(true);

  //   const uploadTask = firebase
  //     .storage()
  //     .ref(`/products/${productImgPath.path.name}`)
  //     .put(productImgPath.path);
  //   //initiates the firebase side uploading
  //   uploadTask.on(
  //     "state_changed",
  //     (snapShot) => {
  //       //takes a snap shot of the process as it is happening
  //       console.log(snapShot);
  //     },
  //     (err) => {
  //       //catches the errors
  //       console.log(err);
  //       toast("Error uploading image");
  //     },
  //     () => {
  //       // gets the functions from storage refences the image storage in firebase by the children
  //       // gets the download url then sets the image from firebase as the value for the imgUrl key:
  //       firebase
  //         .storage()
  //         .ref("products")
  //         .child(productImgPath.path.name)
  //         .getDownloadURL()
  //         .then((fireBaseUrl) => {
  //           logProduct(fireBaseUrl);
  //         });
  //     }
  //   );
  // };

  const handleFormState = () => {
    form
      .validateFields()
      .then((values) => {
        handleFormData(values);
        console.log(formData, "___STAGE_DATA___");
      })
      .catch((errorInfo) => {
        console.log("errorInfo ...", errorInfo);
      });
  };

  const onNextFailed = () => {
    toast.error("Please check your form, fill in the required field");
  };

  const onFinishFailed = (errorInfo) => {
    // toast.error("Please check your form, fill in the required field");
    // console.log("Failed:", errorInfo);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prevState) => [...prevState, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const filesDiv = files.map((file) => (
    <li key={file.path}>
      - {file.path}
      {/* - {file.size} bytes */}
    </li>
  ));

  const DecisionMakerDiv = ({ id, required }) => {
    return (
      <>
        <div className="col-md-6 col-12 mb-3 mt-auto">
          <label>Name</label>
          <Form.Item
            name={"name" + id}
            rules={[
              {
                required: required,
                message: "Required!",
              },
            ]}
          >
            <Input type="text" className="form-control" />
          </Form.Item>
        </div>

        <div className="col-md-6 col-12 mb-3 mt-auto">
          <label>Email</label>
          <Form.Item
            name={"email" + id}
            rules={[
              {
                required: required,
                message: "Required!",
              },
            ]}
          >
            <Input type="email" className="form-control" />
          </Form.Item>
        </div>
        <div className="col-md-6 col-12 mb-3 mt-auto">
          <label>Phone Number</label>
          <Form.Item
            name={"phone" + id}
            rules={[
              {
                required: required,
                message: "Required!",
              },
            ]}
          >
            <Input type="text" className="form-control" />
          </Form.Item>
        </div>
        <div className="col-md-6 col-12 mb-3 mt-auto" />
      </>
    );
  };

  return (
    <div className="main-wrapper">
      <div className="body">
        <ToastContainer />

        <section className="main-container questionaire-wrapper">
          <section>
            <Navbar />
            <Sidebar />
            <div className="questionaire-header container">
              <h2>Client Questionaire</h2>
              <p>
                {section == 0
                  ? `Unlike our monthly maintenance plans, no two project are the same.  Each project requires a full explanation of what your goals are and what the aesthetics of your web page or app will look like.  In order to meet all your needs we do require you to talk to one of our sales agent who will take as much information down and present it to the development team.  Once the development team has determined the work involved in your personalized project, we will then provide you with a quote which you can either accept or reject.  Our rates are very competitive and we are confident that you will accept our project cost.  Please click here to setup an account and submit your project. A sales agent will contact you within 24 hours.`
                  : `Please take your time and answer all the questions. Web design
                and development is a collaborative process and needs your
                involvement in all stages.`}
              </p>
              {section >= 1 && (
                <ProgressBar
                  baseBgColor={"#fff"}
                  bgColor={"#ff4d4f"}
                  labelColor={"#ff4d4f"}
                  isLabelVisible={false}
                  height={"13px"}
                  completed={section < 7 ? section * 1.7 * 10 : section * 10}
                />
              )}
            </div>
          </section>

          {section == 0 ? (
            <div className={"container w-100 text-center mb-5 reduce-top"}>
              <button
                class="btn btn-orange"
                onClick={() => {
                  setSection(1);
                }}
              >
                Continue
                <span
                  class="iconify"
                  data-icon="bytesize:arrow-right"
                  data-inline="false"
                />
              </button>
            </div>
          ) : (
            <section className="container mb-5 reduce-top">
              <Form
                form={form}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                // onFinish={() => {
                //   // onFinish
                // }}
                // onFinishFailed={onFinishFailed}
              >
                {section == 1 && (
                  <div className="mb-3 mt-5">
                    <h4 className="mb-4 font-bold">
                      Personal information of person in charge of project
                    </h4>

                    <div className="row">
                      <DecisionMakerDiv id={""} />

                      {decisionMakers > 1 && (
                        <DecisionMakerDiv id={"2"} required={false} />
                      )}
                      {decisionMakers > 2 && (
                        <DecisionMakerDiv id={"3"} required={false} />
                      )}

                      <div className="col-12 mb-3 mt-auto click">
                        <div className={"d-flex row mx-auto"}>
                          <p
                            onClick={() => {
                              if (decisionMakers < 3)
                                setDecisionMakers(decisionMakers + 1);
                            }}
                            className={decisionMakers > 1 ? "add_txt" : ""}
                          >
                            {decisionMakers > 1
                              ? "+"
                              : "+ Add another decision maker"}
                          </p>

                          {decisionMakers > 1 && (
                            <p
                              onClick={() => {
                                if (decisionMakers > 0)
                                  setDecisionMakers(decisionMakers - 1);
                              }}
                              className={"add_txt ml-2"}
                            >
                              -
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  // <hr />
                )}

                {section == 2 && (
                  <div className="mb-3 mt-5">
                    <h4 className="mb-4 font-bold">Company Contact</h4>

                    <div className="row">
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>Business / Company Name</label>
                        <Form.Item
                          name="business_name"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input type="text" className="form-control" />
                        </Form.Item>
                      </div>
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>Email</label>
                        <Form.Item
                          name="business_email"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input type="email" className="form-control" />
                        </Form.Item>
                      </div>
                      <div className="col-12 mb-3 mt-auto">
                        <label>Street Address</label>
                        <Form.Item
                          name="address"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <TextArea rows="4" className="form-control" />
                        </Form.Item>
                      </div>
                      <div className="col-md-4 col-12 mb-3 mt-auto">
                        <label>City</label>
                        <Form.Item
                          name="city"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input type="text" className="form-control" />
                        </Form.Item>
                      </div>
                      <div className="col-md-4 col-12 mb-3 mt-auto">
                        <label>State</label>
                        <Form.Item
                          name="state"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input type="text" className="form-control" />
                        </Form.Item>
                      </div>
                      <div className="col-md-4 col-12 mb-3 mt-auto">
                        <label>Zip/Postal Code</label>
                        <Form.Item
                          name="postalCode"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input type="text" className="form-control" />
                        </Form.Item>
                      </div>

                      <div className="col-md-8 col-12 mb-3 mt-auto">
                        <label>Website Address / Desired domain</label>
                        <Form.Item
                          name="webAddress"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="www.mywebsite.com"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                )}

                {section == 3 && (
                  <div className="mb-3 mt-5">
                    <h4 className="mb-4 font-bold">
                      Tell me about Your Project.
                    </h4>
                    <div className="row">
                      <div className="col-12 mb-3 mt-auto">
                        <label>
                          Please give us a brief overview of the company, what
                          you do or produce?
                        </label>
                        <Form.Item
                          name="briefOverview"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <TextArea rows="3" className="form-control" />
                        </Form.Item>
                      </div>
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          What is the purpose of this site/Project?{" "}
                          {/* <span className="text-red">*</span> */}
                        </label>
                        <Form.Item
                          name="purpose"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Checkbox.Group
                            name="purposeCheckList"
                            className="flexed-checkbox"
                            onChange={(checkedValue) => {
                              // toast(checkedValue.toString())
                              setPurposeCheckList(checkedValue);
                            }}
                            options={[
                              "Bring in new clients to your business",
                              "Provide your customers with information on a certain subject ",
                              "Explain your products and services ",
                              "Deliver news or calendar of events",
                              "Create a blog that addresses specific topics or interests",
                              "Sell a product or products online",
                              "Provide support for current clients",
                              "Others",
                            ]}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          Do you have a time frame or deadline to get this
                          project?
                          <span className="text-red">*</span>
                        </label>
                        <Form.Item
                          name="deadline"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input type="text" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                )}

                {section == 4 && (
                  <div className="mb-3 mt-5">
                    <h4 className="mb-4 font-bold">Content</h4>
                    <p>
                      What are you offering? Make a list of all the
                      sections/pages you think that you'll need. (Samples below
                      are just an example to get you started, please fill this
                      out completely.)
                    </p>
                    <div className="row">
                      <div className="col-12 mb-3 mt-auto">
                        <label>
                          Do you have the written content and images/photographs
                          prepared for these pages?
                        </label>
                        <br />
                        <Form.Item
                          name="writtenContent"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Radio.Group options={["Yes", "No"]} />
                        </Form.Item>
                      </div>
                      <div className="col-12 mb-3 mt-auto">
                        <label>
                          If not, will you need copywriting and photography
                          services?
                        </label>
                        <Form.Item name="copywriting">
                          <div className="checkbox-wrapper">
                            <br />
                            <Radio.Group options={["Yes", "No"]} />
                          </div>
                        </Form.Item>
                      </div>
                      <div className="col-12 mb-3 mt-auto">
                        <label>
                          Are you willing to commit time/effort into learning
                          how to use Content Management System (CMS) and edit
                          your site?
                        </label>
                        <Form.Item
                          name="editYourself"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <div className="checkbox-wrapper">
                            <br />
                            <Radio.Group options={["Yes", "No"]} />
                          </div>
                        </Form.Item>
                      </div>
                      <div className="col-12 mb-3 mt-auto">
                        <label>Is this a project re-design?</label>
                        <Form.Item
                          name="reDesign"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <div className="checkbox-wrapper">
                            <br />
                            <Radio.Group options={["Yes", "No"]} />
                          </div>
                        </Form.Item>
                      </div>
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          If yes, can you please explain what is working and not
                          working on your current project?
                        </label>
                        <Form.Item
                          name="explainIfNotWorking"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input type="text" className="form-control" />
                        </Form.Item>
                      </div>
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          Are you going to need? e-commerce (sell products) or
                          Membership of any kind
                        </label>
                        <Form.Item
                          name="needEcommerce"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input type="text" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  // <hr />
                )}

                {section == 5 && (
                  <div className="mb-3 mt-5">
                    <h4 className="mb-4 font-bold">Design</h4>
                    <div className="row">
                      <div className="col-12 mb-3 mt-auto">
                        <label>
                          People are coming to your website/project for the
                          first time. How do you want them to feel about your
                          company?
                        </label>
                        <Form.Item
                          name="feelingAboutYourCompany"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <TextArea rows="3" className="form-control" />
                        </Form.Item>
                      </div>
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          If you do not already have a logo, are you going to
                          need one designed?
                        </label>
                        <br />
                        <Form.Item
                          name="haveLogo"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Radio.Group options={["Yes", "No"]} />
                        </Form.Item>
                      </div>

                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          Is there a specific look and feel that you have in
                          mind?
                        </label>
                        <br />
                        <Form.Item
                          name="specificLogoLook"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Radio.Group options={["Yes", "No"]} />
                        </Form.Item>
                      </div>
                    </div>

                    {/* Reference */}
                    <div className="mb-3 mt-5">
                      <h4 className="mb-4 font-bold">
                        Reference Websites/Apps
                      </h4>
                      <div className="row">
                        <div className="col-12">
                          <label>
                            Please include upto 3 links of reference
                            websites/applications.
                          </label>
                        </div>
                        <div className="col-md-4 col-12 mb-3 mt-auto">
                          <Form.Item
                            name="websiteReference1"
                            rules={[
                              {
                                required: false,
                                message: "Required!",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Link 1"
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-4 col-12 mb-3 mt-auto">
                          <Form.Item
                            name="websiteReference2"
                            rules={[
                              {
                                required: false,
                                message: "Required!",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Link 2"
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-4 col-12 mb-3 mt-auto">
                          <Form.Item
                            name="websiteReference3"
                            rules={[
                              {
                                required: false,
                                message: "Required!",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Link 3"
                            />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="col-12">
                        <label>
                          What do you like and don't like about them? What would
                          you like to do differently?
                        </label>
                      </div>
                      <Form.Item
                        name="referenceSitesPreference"
                        rules={[
                          {
                            required: false,
                            message: "Required!",
                          },
                        ]}
                      >
                        <TextArea rows="3" className="form-control" />
                      </Form.Item>
                    </div>
                  </div>
                )}

                {section == 6 && (
                  <>
                    <div className="mb-3 mt-5">
                      <h4 className="mb-4 font-bold">Domain and Hosting</h4>
                      <div className="row">
                        <div className="col-12 mb-3 mt-auto">
                          <label>Do you already own a domain name(s)?</label>
                          <Form.Item
                            name="domainName"
                            rules={[
                              {
                                required: false,
                                message: "Required!",
                              },
                            ]}
                          >
                            <Input type="text" className="form-control" />
                          </Form.Item>
                        </div>
                        <div className="col-md-6 col-12 mb-3 mt-auto">
                          <label>Do you have a hosting account already?</label>
                          <br />
                          <Form.Item
                            name="hostingAccount"
                            rules={[
                              {
                                required: false,
                                message: "Required!",
                              },
                            ]}
                          >
                            <Radio.Group options={["Yes", "No"]} />
                          </Form.Item>
                        </div>

                        <div className="col-md-6 col-12 mb-3 mt-auto">
                          <label>
                            If yes, do you have the login information?
                          </label>
                          <br />
                          <Form.Item name="loginInfo">
                            <Radio.Group options={["Yes", "No"]} />
                          </Form.Item>
                        </div>

                        <div className="col-md-6 col-12 mb-3 mt-auto">
                          <label>If no, what name(s) would you like?</label>
                          <br />
                          <Form.Item name="preferredHostingName">
                            <Input className="form-control" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <p>Select File (optional), Click or Drop files below box</p>

                    {/* <Dragger {...dragger_props} className={"mb-4 flex-center text-center"}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload.
                      </p>
                    </Dragger> */}

                    <div
                      {...getRootProps({ className: "dropzone" })}
                      className="import-box my-5 d-flex flex-column"
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>Drop the files here ...</p>
                      ) : (
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files <br /> All file types are supported.
                        </p>
                      )}
                      <aside>
                        <h4 className="my-3">Files</h4>
                        <ul className="text-blue">{filesDiv}</ul>
                      </aside>
                    </div>
                  </>
                )}

                {section == 7 && (
                  <div className="mb-3 mt-5">
                    <h4 className="mb-4 font-bold">Review</h4>

                    <div className="col-12 mb-3 mt-auto">
                      <label>
                        From your answers, We detect you might need our seo
                        package. Please Click Next if you wish to proceed or
                        finish to complete this questionaire
                      </label>
                    </div>
                  </div>
                )}

                {section == 8 && (
                  <div className="mb-3 mt-5">
                    <h4 className="mb-4 font-bold">Target market</h4>
                    <div className="row">
                      <div className="col-12 mb-3 mt-auto">
                        <label>
                          Who will visit this site? Describe your potential
                          clients.
                        </label>
                        <Form.Item
                          name="potentialClients"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <TextArea rows="3" className="form-control" />
                        </Form.Item>
                      </div>
                      <div className="col-12 mb-3 mt-auto">
                        <label>
                          Why do you believe site visitors should do business
                          with you rather than with a competitor? What problem
                          are you solving for them?
                        </label>
                        <Form.Item
                          name="problemSolving"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <TextArea rows="3" className="form-control" />
                        </Form.Item>
                      </div>
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          What action(s) should the user perform when visiting
                          your site?
                        </label>
                        <Form.Item name="actionsUserPerform">
                          <Checkbox.Group
                            className="flexed-checkbox"
                            options={[
                              "Call you",
                              "Fill out a contact form",
                              "Fill out a quote form",
                              "Sign up for your mailing list",
                              "Search for information",
                              "Purchase a product(s)",
                              "Others",
                            ]}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  // <hr />
                )}

                {section == 9 && (
                  <div className="mb-3 mt-5">
                    <h4 className="mb-4 font-bold">Marketing The Site</h4>
                    <div className="row">
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          How do people find out about your business right now?
                        </label>
                        <Form.Item
                          name="howPeopleFindBussiness"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input type="text" className="form-control" />
                        </Form.Item>
                      </div>
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          Have you thought about how you're going to market this
                          site?
                        </label>
                        <Form.Item
                          name="marketingThought"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input type="text" className="form-control" />
                        </Form.Item>
                      </div>
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          Do you have any social network accounts setup?
                        </label>
                        <Form.Item
                          name="marketingSocialChoice"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Checkbox.Group
                            options={["Facebook", "Twitter", "Others"]}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          Do you want links to those accounts in your
                          project/website?
                        </label>
                        <br />
                        <Form.Item
                          name="socialLinkAccess"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Radio.Group options={["Yes", "No"]} />
                        </Form.Item>
                      </div>
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>Do you have a mail service account?</label>
                        <br />
                        <Form.Item
                          name="mailServiceAccount"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Radio.Group options={["Yes", "No"]} />
                        </Form.Item>
                      </div>
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          Will you want to build your mailing list and use it
                          for advertising & newsletters?
                        </label>
                        <br />
                        <Form.Item
                          name="advertiseNewsletters"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Radio.Group options={["Yes", "No"]} />
                        </Form.Item>
                      </div>
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          Will you want printed materials (business cards,
                          catalog, etc.) produced as well?
                        </label>
                        <Form.Item
                          name="printedAdvert"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <br />
                          <Radio.Group options={["Yes", "No"]} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                )}

                {section == 10 && (
                  <div className="mb-3 mt-5">
                    <h4 className="mb-4 font-bold">Maintenance</h4>
                    <div className="row">
                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          Will there be sections that need regular updating?
                        </label>
                        <br />
                        <Form.Item
                          name="regularUpdate"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Radio.Group options={["Yes", "No"]} />
                        </Form.Item>
                      </div>

                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          Would you like to be able to do most of the updating
                          yourself?
                        </label>
                        <br />
                        <Form.Item name="selfUpdate">
                          <Radio.Group options={["Yes", "No"]} />
                        </Form.Item>
                      </div>

                      <div className="col-md-6 col-12 mb-3 mt-auto">
                        <label>
                          If you're planning on writing a blog do you already
                          have several things written?Do you already write on a
                          regular basis?
                        </label>
                        <br />
                        <Form.Item
                          name="writingRegularBasis"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Radio.Group options={["Yes", "No"]} />
                        </Form.Item>
                      </div>
                      <div className="col-12 mb-3 mt-auto">
                        <label>
                          Are there any features/pages that you don't need now
                          but may want in the future? Please be as specific and
                          future thinking as possible.
                        </label>
                        <Form.Item
                          name="featuresPages"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <TextArea rows="3" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                )}

                <div className="text-center">
                  {loading ? (
                    <div className={"w-100 flex-center spinner mb-4"}>
                      <ReactLoading
                        className={"spinner"}
                        type={"spin"}
                        color={"#eb5424"}
                        height={"7%"}
                        width={"7%"}
                      />
                    </div>
                  ) : (
                    <div className={"w-100 d-flex justify-content-between"}>
                      <button
                        class="btn btn-orange"
                        onClick={() => {
                          if (section > 1) setSection(section - 1);
                        }}
                      >
                        {/* <span
                          class="iconify-left"
                          data-icon="bytesize:arrow-left"
                          data-inline="false"
                        /> */}
                        Back
                      </button>

                      <div>
                        {section == 7 && (
                          <button
                            class="btn btn-orange mb-2 mr-2"
                            onClick={() => {
                              btnClick();
                            }}
                          >
                            {loading ? (
                              <div className={"flex-center spinner"}>
                                <ReactLoading
                                  className={"spinner"}
                                  type={"spin"}
                                  color={"#fff"}
                                  height={"100%"}
                                  width={"70%"}
                                />
                              </div>
                            ) : (
                              "Finish"
                            )}
                          </button>
                        )}

                        <button
                          class="btn btn-orange mb-2"
                          onClick={() => btnClick()}
                        >
                          {section == 10 || section == 6 ? "Submit" : "Next"}

                          <span
                            class="iconify"
                            data-icon="bytesize:arrow-right"
                            data-inline="false"
                          />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            </section>
          )}
          <Footer className="mt-auto" />
        </section>
      </div>
    </div>
  );
};
