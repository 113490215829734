import React, { useState, useEffect } from "react";
import logo from "../assets/img/logo.png";
import { Link, Redirect, withRouter } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";

const CheckoutForm = (props) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, toast] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  // const key = location.state.key;

  const paymentload = props;
  // const key = paymentload.client_secret;
  // const group_id = paymentload.group_id;
  // const fullName =
  //   JSON.parse(localStorage.getItem("user")).first_name +
  //   " " +
  //   JSON.parse(localStorage.getItem("user")).last_name;

  useEffect(() => {
    // setClientSecret(key);
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    toast(event.error ? event.error.message : "");
  };

  return (
    <section className="signIn-wrapper">
      <div className="container">
        <div className="sign-container">
          <div>
            <div className="my-5 logo-wrapper">
              <Link to="/">
                <img src={logo} alt="" className="login-logo" />
              </Link>
            </div>
            <div className="row my-5">
              <div className="col-lg-7 col-sm-12 mx-auto">
                <div>
                  <form
                    id="payment-form"
                    //  onSubmit={handleSubmit}
                  >
                    <CardElement
                      id="card-element"
                      options={cardStyle}
                      onChange={handleChange}
                    />
                    <button
                      disabled={processing || disabled || succeeded}
                      id="submit"
                    >
                      <span id="button-text">
                        {processing ? (
                          <div className="spinner" id="spinner"></div>
                        ) : (
                          "Pay"
                        )}
                      </span>
                    </button>
                    {/* Show any error that happens when processing the payment */}
                    {error && (
                      <div className="card-error" role="alert">
                        {error}
                      </div>
                    )}
                    {/* Show a success message upon completion */}
                    <p
                      className={
                        succeeded ? "result-message" : "result-message hidden"
                      }
                    >
                      Payment succeeded, see the result in your
                      <a href={`https://dashboard.stripe.com/test/payments`}>
                        {" "}
                        Stripe dashboard.
                      </a>{" "}
                      Refresh the page to pay again.
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
CheckoutForm.prototype = {
  setAlert: PropTypes.func.isRequired,
  createUserSession: PropTypes.func.isRequired,
};

export default CheckoutForm;
