import React, { useState, useEffect } from "react";
import logo from "../../assets/img/logo.png";
import "./auth.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import appConfig from "../../config/fire";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import { MANAGEMENT, PLAN_A, PLAN_B, PLAN_C } from "../../config/constant";

export const Auth = () => {
  const location = useLocation();
  const history = useHistory();

  const auth = location.state?.auth;
  const plan = location.state?.plan;
  const price = location.state?.price;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [loading, setloading] = useState(false);
  const [isAdmin, setAdmin] = useState(false);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { name, email, password } = formData;

  const [showRegister, setShowRegister] = useState(false);

  useEffect(() => {
    if (auth === "join") {
      setShowRegister(true);
    } else {
      setShowRegister(false);
    }

    let listenr = appConfig.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        handleNav();
        listenr();
      }
    });
  }, []);

  const authInit = (e) => {
    e.preventDefault();
    if (showRegister) signUp();
    else login();
  };

  const ResetPassword = (e) => {
    e.preventDefault();

    if (!email) {
      toast("Email Address is required");
      return;
    }

    appConfig.firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function (user) {
        toast(
          "Please check your email, We have sent you instructions to reset your password"
        );
      })
      .catch(function (e) {
        alert(e);
        console.log(e);
      });
  };

  const signUp = (e) => {
    if (!name) {
      toast("Individual/Business name is required");
      return;
    }
    if (!email) {
      toast("Email Address is required");
      return;
    }

    if (!password) {
      toast("Password is required");
      return;
    }

    setloading(true);

    appConfig.firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(
        function (user) {
          var user = appConfig.firebase.auth().currentUser;

          logAccount(user);
        },
        function (error) {
          // Handle Errors here.
          setloading(false);
          var errorMessage = error.message;
          toast(errorMessage);
          console.log(errorMessage);
        }
      );
  };

  const login = (e) => {
    if (!email) {
      toast("Email Address is required");
      return;
    }

    if (!password) {
      toast("Password is required");
      return;
    }

    setloading(true);

    appConfig.firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        setloading(false);
        handleNav();
      })
      .catch((error) => {
        setloading(false);
        toast("" + error.message);
        console.log("Login Error: " + error);
      });
  };

  const logAccount = (user) => {
    var snap = {
      name,
      email,
      plan: plan || "NA",
      photoUrl: "na",
      last_logged_in: Date.now(),
      createdAt: Date.now(),
    };

    appConfig.firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .set(snap, { merge: false })
      .then((snapshot) => {
        setloading(false);
        handleNav();
      })
      .catch((error) => {
        toast("Error saving details." + error);
        setloading(false);
        console.log(error);
      });
  };

  const handleNav = () => {
    if (isAdmin) {
      history.push("/admin");
    } else if (
      plan == MANAGEMENT ||
      plan == "A" ||
      plan == "B" ||
      plan == "C"
    ) {
      history.push({
        pathname: `/stripe`,
        state: { plan, price },
      });
    } else if (!plan || plan == "") {
      history.push("/dashboard");
    } else {
      history.push({ pathname: "/questionaire", state: { plan } });
    }
  };

  return (
    <div className="login-wrapper">
      <Sidebar whiteBg />
      <ToastContainer />

      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5  col-md-12">
              <div className="login-content text-left">
                <div className="logo">
                  <Link to="/">
                    <img src={logo} alt="Solnary" />
                  </Link>
                </div>
                <div className="login-header">
                  <h3>Your project deserves a creative team</h3>
                  {showRegister === false ? (
                    <p>Welcome back, login to get started</p>
                  ) : (
                    <p>Register to get started</p>
                  )}
                </div>

                <form className="login-form">
                  <div className="form-group">
                    {showRegister === true ? (
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="FullName"
                          placeholder="Individual/Business Name"
                          autoFocus
                          value={name}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    ) : null}
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="Email"
                      placeholder="Email Address"
                      autoFocus
                      value={email}
                      onChange={(e) => onChange(e)}
                    />
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <label className="check-container">
                        <input type="checkbox" />
                        <span className="checkmark"></span>
                        <span className=" font14 remember ml-1">
                          Remember me
                        </span>
                      </label>
                    </div>
                    {showRegister === false ? (
                      <div
                        className="font14 text-grey click"
                        data-toggle="modal"
                        data-target="#forgotPasswordModal"
                      >
                        Forgot Password?
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={showRegister === true ? "mt-3 mb-4" : "my-5 "}
                  >
                    {showRegister === false ? (
                      <button
                        className="btn btn-red login-btn mr-3"
                        onClick={authInit}
                      >
                        {loading ? (
                          <div className={"flex-center spinner"}>
                            <ReactLoading
                              className={"spinner"}
                              type={"spin"}
                              color={"#fff"}
                              height={"100%"}
                              width={"70%"}
                            />
                          </div>
                        ) : (
                          "Login"
                        )}
                      </button>
                    ) : (
                      <>
                        <div className="my-3 policy-agreement font-AftaSans font12">
                          By continuing, you're confirming that you've read and
                          agree to our {""}
                          <Link className="text-red" to="#">
                            terms & conditions
                          </Link>
                          {" and "}
                          <Link className="text-red" to="#">
                            privacy policy.
                          </Link>
                        </div>
                        <button
                          className="btn btn-outline-red login-btn "
                          onClick={authInit}
                        >
                          {loading ? (
                            <div className={"flex-center spinner"}>
                              <ReactLoading
                                className={"spinner"}
                                type={"spin"}
                                color={"#eb5424"}
                                height={"100%"}
                                width={"70%"}
                              />
                            </div>
                          ) : (
                            "Sign Up"
                          )}
                        </button>
                      </>
                    )}
                  </div>
                </form>
                <div className="footer-content">
                  {showRegister === false ? (
                    <p>
                      <span className="text-grey mr-2">
                        Don't have an account?
                      </span>
                      <span
                        className="text-red click"
                        onClick={() => {
                          setShowRegister(true);
                        }}
                      >
                        SignUp
                      </span>
                    </p>
                  ) : (
                    <p>
                      <span className="text-grey mr-2">
                        Already have an Account?
                      </span>
                      <span
                        className="text-red click"
                        onClick={() => {
                          setShowRegister(false);
                        }}
                      >
                        Login
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div
              className={
                showRegister === false
                  ? "col-lg-7  col-md-12 right-section login-bg d-none d-lg-block"
                  : "col-lg-7  col-md-12 right-section register-bg d-none d-lg-block"
              }
            >
              {/* <SmallFooter className='mt-auto'/> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="forgotPasswordModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="forgotPasswordModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title font-bold"
                id="forgotPasswordModalLabel"
              >
                Forgot Password
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mt-3 mb-5">
                <div className="text-center text-grey mb-4">
                  Please enter the email address associated with your account.
                </div>
                <form>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="Email"
                      placeholder="Email Address"
                      autoFocus
                      value={email}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </form>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-outline-red mr-3"
                  data-dismiss="modal"
                  onClick={(e) => ResetPassword(e)}
                >
                  Submit
                </button>
                <button className="btn btn-red" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
