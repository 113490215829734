import React from "react";
import { Navbar } from "../../components/Navbar";
import { Sidebar } from "../../components/Sidebar";
import { useHistory, useLocation } from "react-router-dom";

const decisionMaker1 = [
  { label: "Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "Phone", value: "phone" },
];
const decisionMaker2 = [
  { label: "Name", value: "name2" },
  { label: "Email", value: "email2" },
  { label: "Phone", value: "phone2" },
];
const decisionMaker3 = [
  { label: "Name", value: "name3" },
  { label: "Email", value: "email3" },
  { label: "Phone", value: "phone3" },
];

const company = [
  { label: "Business Name", value: "business_name" },
  { label: "Business Email", value: "business_email" },
  { label: "Address", value: "address" },
  { label: "City", value: "city" },
  { label: "State", value: "state" },
  { label: "Postal Code", value: "postalCode" },
  { label: "Website Address", value: "webAddress" },
  { label: "Progress", value: "progress" },
  { label: "Status", value: "status" },
];

const aboutProject = [
  { label: "About", value: "briefOverview" },
  { label: "Purpose", value: "purpose" },
  { label: "Deadline", value: "deadline" },
];

const projectContent = [
  { label: "Assets Prepared", value: "writtenContent" },
  { label: "Need Assets Assistance", value: "copywriting" },
  { label: "Will you handle project assets", value: "editYourself" },
  { label: "Is Project a Re-design", value: "reDesign" },
  { label: "Redesign request", value: "explainIfNotWorking" },
  { label: "e-Commerce/Membership?", value: "needEcommerce" },
];

const projectDesign = [
  { label: "Project Feel", value: "feelingAboutYourCompany" },
  { label: "Have a logo?", value: "haveLogo" },
  { label: "Logo idea", value: "specificLogoLook" },
  { label: "Website Reference 1", value: "websiteReference1" },
  { label: "Website Reference 2", value: "websiteReference2" },
  { label: "Website Reference 3", value: "websiteReference3" },
  { label: "Website Reference info", value: "referenceSitesPreference" },
];

const domainHosting = [
  { label: "Domain Name", value: "domainName" },
  { label: "Have Hosting?", value: "hostingAccount" },
  { label: "Access Hosting provider account?", value: "loginInfo" },
  { label: "Prefered Domain Name", value: "preferredHostingName" },
];

export const ProjectDetail = () => {
  const history = useHistory();
  const location = useLocation();

  const project = location?.state?.project || {};

  const ChildItem = (label, value) => {
    return (
      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-0">
        <p className="mb-1 font12 label font-bold">{label}</p>
        <p className="font16 value">{project[`${value}`]}</p>
      </div>
    );
  };

  return (
    <div className="project-details-wrapper">
      <div className="body">
        <section className="body-container">
          <Navbar whiteBg />
          <Sidebar whiteBg />
          <div className="container py-5">
            <div
              className="mt-5 mb-3"
              onClick={() => {
                history.goBack();
              }}
            >
              <span
                class="iconify"
                data-icon="bytesize:arrow-left"
                data-inline="false"
              />
            </div>

            <div className="bg-white br-8 box-shadow p-4">
              <div className="row">
                <p className="col-12 mb-3 headerLabel  font-bold">
                  Project Team
                </p>

                {decisionMaker1.map((element) => {
                  if (project[`${element.value}`])
                    return ChildItem(element.label, element.value);
                })}

                <span className={"my-hr"} />

                {decisionMaker2.map((element) => {
                  if (project[`${element.value}`])
                    return ChildItem(element.label, element.value);
                })}

                <span className={"my-hr"} />

                {decisionMaker3.map((element) => {
                  if (project[`${element.value}`])
                    return ChildItem(element.label, element.value);
                })}

                <span className={"my-hr"} />

                <p className="col-12 mb-3 headerLabel font-bold">Company</p>
                {company.map((element) => {
                  if (project[`${element.value}`])
                    return ChildItem(element.label, element.value);
                })}

                <span className={"my-hr"} />

                <p className="col-12 mb-3 headerLabel font-bold">
                  Project Overview
                </p>

                {aboutProject.map((element) => {
                  if (project[`${element.value}`])
                    return ChildItem(element.label, element.value);
                })}

                <span className={"my-hr"} />

                <p className="col-12 mb-3 headerLabel font-bold">Content</p>
                {projectContent.map((element) => {
                  if (project[`${element.value}`])
                    return ChildItem(element.label, element.value);
                })}

                <span className={"my-hr"} />

                <p className="col-12 mb-3 headerLabel font-bold">Design</p>
                {projectDesign.map((element) => {
                  if (project[`${element.value}`])
                    return ChildItem(element.label, element.value);
                })}

                <span className={"my-hr"} />

                <p className="col-12 mb-3 headerLabel font-bold">Hosting</p>

                {domainHosting.map((element) => {
                  if (project[`${element.value}`])
                    return ChildItem(element.label, element.value);
                })}

                <hr className={"my-hr"} />

                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                  <p className="mb-1 font12 label font-bold">Documents </p>
                  <div className="mb-4">
                    <button
                      className="btn btn-download"
                      onClick={() => {
                        history.push({
                          pathname: `/receipt/${project.projectName}`,
                          state: { project },
                        });
                      }}
                    >
                      Receipts
                    </button>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                  <p className="mb-1 font12 label font-bold">
                    Project Screenshots
                  </p>
                  <p className="font16 value click"></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
