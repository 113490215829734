import React, { useState } from "react";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";
import appConfig from "../config/fire";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const typeApp = "App/Website";
const typeSupport = "Support/Management";
const projectPlacholder =
  "Choose the service you want?  - We are here to make a difference!";

export const ChooseService = () => {
  const history = useHistory();

  const [project, setProject] = useState(
    `Choose the service you want?  - We are here to make a difference!`
  );

  const [selected, setSelected] = useState(false);

  const [formData, setFormData] = useState({
    projectName: "",
    website: "",
    description: "",
  });

  const [loading, setloading] = useState(false);

  const { projectName, website, description } = formData;
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const initProject = () => {
    if (!projectName) {
      toast("Project name is required");
      return;
    }

    if (!description) {
      toast("Please enter your project description");
      return;
    }

    toast("Creating your new project.");

    var uid = appConfig.firebase.auth().currentUser.uid;
    var snap = {
      userId: uid,
      projectType: project,
      projectName,
      website,
      description,
      status: "NEW",
      createdAt: Date.now(),
    };

    appConfig.firebase
      .firestore()
      .collection("projects")
      .add(snap)
      .then((snapshot) => {
        setloading(false);
        history.push("/dashboard");
      })
      .catch((error) => {
        toast("Error saving details." + error);
        setloading(false);
        console.log(error);
      });
  };

  return (
    <div className="home-wrapper">
      <div className="body">
        <section className="body-container">
          <Navbar whiteBg />
          <Sidebar whiteBg />
          <ToastContainer />

          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-10 mx-auto">
                <div className="choose-title mb-5 text-center row">
                  {selected && (
                    <div
                      className={"px-2 click"}
                      onClick={() => {
                        setSelected(false);
                        setProject(projectPlacholder);
                      }}
                    >
                      {"<"}
                    </div>
                  )}
                  <div className={"flex-grow-1"}>{project} </div>
                </div>
              </div>
            </div>

            {selected ? (
              <>
                {project === typeApp ? (
                  <div className="choose-card mb-5">
                    <form className="login-form">
                      <input
                        className="form-control"
                        name="projectName"
                        id="projectName"
                        placeholder="Project name"
                        autoFocus
                        value={projectName}
                        onChange={(e) => onChange(e)}
                      />
                      <input
                        className="form-control"
                        name="website"
                        id="website"
                        placeholder="Website url (if applicable)"
                        autoFocus
                        value={website}
                        onChange={(e) => onChange(e)}
                      />
                      <textarea
                        className="form-control form-area"
                        name="description"
                        id="description"
                        placeholder="Project Description"
                        value={description}
                        onChange={(e) => onChange(e)}
                      />
                    </form>
                    <button className="btnSol" onClick={initProject}>
                      Create
                    </button>
                  </div>
                ) : (
                  <div className="choose-card mb-5">
                    <form className="login-form">
                      <input
                        className="form-control"
                        name="projectName"
                        id="projectName"
                        placeholder="Project name"
                        autoFocus
                        value={projectName}
                        onChange={(e) => onChange(e)}
                      />

                      <textarea
                        className="form-control form-area"
                        name="description"
                        id="description"
                        placeholder="Support Description"
                        value={description}
                        onChange={(e) => onChange(e)}
                      />
                    </form>
                    <button className="btnSol" onClick={initProject}>
                      Send
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="row">
                <div className="col-lg-10 col-md-12 mx-auto">
                  <div className="d-flex justify-content-center">
                    <div className="col-6 mb-2">
                      <div
                        className="card2"
                        onClick={() => {
                          setProject(typeApp);
                          setSelected(true);
                        }}
                      >
                        App <br /> or Website
                        <div class="go-corner" href="#">
                          <div class="go-arrow">→</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 mb-2">
                      <div
                        className="card2"
                        onClick={() => {
                          setProject(typeSupport);
                          setSelected(true);
                        }}
                      >
                        Support <br /> or Management
                        <div class="go-corner" href="#">
                          <div class="go-arrow">→</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>

        <Footer className="mt-auto" />
      </div>
    </div>
  );
};
