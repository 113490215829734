import React from "react";
import { Footer } from "../components/FooterV";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";
import { PlanSection } from "./PlanSection";

export const UserSubsscription = () => {
  return (
    <div className="">
      <header>
        <Sidebar />
        {/* <Navbar /> */}
        {/* <div className="container mt-4">
          <h2>FAQ</h2>
          <p>Frequently asked questions.</p>
        </div> */}
      </header>
      <section className="">
        <PlanSection />
      </section>
      <Footer />
    </div>
  );
};
