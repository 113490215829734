import React, { useState, useEffect } from "react";
import Psmall from "../../assets/img/profile-small.png";
import TimeAgo from "react-timeago";
import fire from "../../config/fire";
import { ToastContainer, toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
require("firebase/database");

const db = fire.firebase.firestore();
const WORKERS = "Workers";

export default function AllUsers(props) {
  const history = useHistory();
  let [allUsers, setAllWorkers] = useState([]);
  let [loading, setLoading] = useState();

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = () => {
    setLoading(true);
    let userItem = [];
    db.collection("users")
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.empty) {
          toast("Error fetching users");
          return;
        }

        querySnapshot.forEach((user) => {
          var itemVal = user.data();
          itemVal.id = user.id;

          userItem.push(itemVal);

          userItem.sort((a, b) =>
            b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
          );
        });

        setAllWorkers(userItem);
        allUsers = userItem;
        console.log("State users => ", allUsers);
        setLoading(false);
      });
  };

  const viewProfile = (user) => {
    history.push({
      pathname: `/user-detail/${user.id}`,
      state: { user },
    });
  };

  const getDateFromStamp = (timestamp) => {
    // const date = new Date(timestamp).toDateString();
    return new Date(parseInt(timestamp)).toDateString();
  };

  if (loading) {
    return (
      <div className="content container">
        <div className="row my-3">
          <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
            <Skeleton height={100} />
          </div>
          <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
            <Skeleton height={100} />
          </div>
          <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
            <Skeleton height={100} />
          </div>
          <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
            <Skeleton height={100} />
          </div>
        </div>
        <Skeleton count={3} height={70} />
        <div className="row mt-3">
          <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
            <Skeleton height={100} />
          </div>
          <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
            <Skeleton height={100} />
          </div>
          <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
            <Skeleton height={100} />
          </div>
          <div className="col-sm-8 col-md-6 col-lg-4 col-xl-3">
            <Skeleton height={100} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="body-with-nav pt-3 small-underlay">
        <ToastContainer />
        <div className="mt-5 container">
          <div className="row mb-5">
            <div
              className="mt-5 mb-3"
              onClick={() => {
                history.goBack();
              }}
            >
              <span
                class="iconify"
                data-icon="bytesize:arrow-left"
                data-inline="false"
              />
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="row container">
                <span className="font20 text-bold text-black">All Users</span>
              </div>
            </div>
          </div>

          <div className="table-responsive card-table">
            <table id="myTable" className="table text-nowrap w-100">
              <thead className="text-grey">
                <tr>
                  <th className="text-normal">Name</th>
                  <th className="text-normal">Email Address</th>
                  <th className="text-normal">Created at</th>
                  <th className="text-normal">Action</th>
                </tr>
              </thead>
              <tbody className="font14 text-black">
                {allUsers.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <img
                            src={
                              user.photoUrl == "na"
                                ? Psmall
                                : user.spotImageUrl
                            }
                            alt="profile"
                            className="profile-img"
                          />
                        </div>
                        <div>{user.name}</div>
                      </div>
                    </td>
                    <td>{user.email}</td>

                    <td>
                      {/* <TimeAgo
                        date={getDateFromStamp(user.last_logged_in)}
                      /> */}
                      <TimeAgo date={getDateFromStamp(user.createdAt)} />
                    </td>
                    <td>
                      <button
                        className="btn bg-blue btn-view"
                        onClick={() => viewProfile(user)}
                      >
                        view
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
