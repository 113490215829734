import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkout";
import { Redirect } from "react-router-dom";
const promise = loadStripe("pk_live_uorEnVQPZJrodoFHTkwcwyvS001QYcUjLh");

const Stripe = ({ location }) => {
  if (!location.state) {
    return <Redirect to="/" />;
  }
  // const client_secret = location.state.key;
  const plan = location.state.plan;
  const price = location.state.price;
  // const paymentload = { client_secret, plan, price };
  const paymentload = { plan, price };
  return (
    <div className="stripe-checkout-wrapper">
      <Elements stripe={promise}>
        <CheckoutForm props={paymentload} />
      </Elements>
    </div>
  );
};

export default Stripe;
