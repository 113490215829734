import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/Navbar";
import { Sidebar } from "../../components/Sidebar";
import { useHistory, useLocation } from "react-router-dom";
import fire from "../../config/fire";
import { toast, ToastContainer } from "react-toastify";
import { STATUS } from "../../config/constant";

export const AllProjects = () => {
  const history = useHistory();
  const location = useLocation();

  const [projects, setProjects] = useState([]);
  const [newProject, setNewProject] = useState([]);
  const [inProgess, setInProgress] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = () => {
    fire.firebase
      .firestore()
      .collection("projects")
      //   .where("userId", "==", uid)
      .get()
      .then(async function (querySnapshot) {
        if (querySnapshot.empty) {
          // toast("No Projects found");
          return;
        }

        let itemsProcessed = 0;

        let projectItem = [];
        let newItems = [];
        let progressItems = [];
        let completedItems = [];

        querySnapshot.forEach((doc, id, array) => {
          var itemVal = doc.data();

          itemVal.id = doc.id;
          itemVal.createdAt = getDateFromStamp(itemVal.createdAt);

          if (itemVal.status == STATUS.NEW) {
            newItems.push(itemVal);
          }

          if (itemVal.status == STATUS.IN_PROGRESS) {
            progressItems.push(itemVal);
          }

          if (itemVal.status == STATUS.COMPLETED) {
            completedItems.push(itemVal);
          }

          projectItem.push(itemVal);

          itemsProcessed++;

          if (itemsProcessed === array?.length) {
          }
        });

        // toast("Ir");
        setNewProject(newItems);
        setInProgress(progressItems);
        setCompleted(completedItems);

        projectItem.sort((a, b) =>
          b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
        );

        setProjects(projectItem);

        setloading(false);
      })
      .catch((error) => {
        toast("Error getting order data");
        console.log("Error getting user data: ", error);
      });
  };

  const getDateFromStamp = (timestamp) => {
    // const date = new Date(timestamp).toDateString();
    return new Date(parseInt(timestamp)).toDateString();
  };

  const viewProject = (element) => {
    // console.log(element);
    history.push({
      pathname: `/project/${element.business_name}`,
      state: { project: element },
    });
  };

  return (
    <div className="project-details-wrapper">
      <ToastContainer />

      <Navbar whiteBg />
      <Sidebar whiteBg />

      <div className="body">
        <section className="body-container">
          <div className="container py-5">
            <div
              className="mt-5 mb-3"
              onClick={() => {
                history.goBack();
              }}
            >
              <span
                class="iconify"
                data-icon="bytesize:arrow-left"
                data-inline="false"
              />
            </div>
          </div>
        </section>

        {projects.length > 0 && (
          <section id={"projects"} className={"container projects"}>
            <h3 className={"mb-0 mt-4"}>PROJECTS</h3>
            <div className={"d-flex flex-wrap"}>
              {projects.map((element) => (
                <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                  <div className="shadowCard2 mx-auto">
                    <p className="plan-txt">
                      -- {element.projectCategory || "maintenance"}
                    </p>
                  </div>
                  <div class="card mx-auto">
                    {/* <p className="">--frameworks</p> */}

                    <h3 class="header1">--{element.business_name}</h3>
                    <div className={"d-flex"}>
                      <h6 className={"status"}>{element.status}</h6>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 ruler" />

                    <div class="content">
                      {/* <ul> */}
                      <li className={"info"}>
                        {/* <span className="mr-2"></span> */}
                        Admin - {element.name}
                      </li>
                      <li className={"info"}>
                        {/* <span className="mr-2"></span> */}
                        Timeline - {element.deadline}
                      </li>
                      {/* </ul> */}

                      <div className={"d-flex justify-content-center"}>
                        <button
                          class="btn btn-price"
                          onClick={() => {
                            viewProject(element);
                          }}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}
      </div>
    </div>
  );
};
