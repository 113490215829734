import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router";
import {
  APPS,
  MANAGEMENT,
  PLAN_A,
  PLAN_B,
  PLAN_C,
  UI_UX,
  WEBSITES,
} from "../config/constant";

const arrayManagement = [
  "SEO Strategy & Plan",
  "Meta on-page Optimization",
  "Premium SEO Plugin",
  "Site Security",
  "Malware Scans & Clean-up",
  "Database Back-up",
  "Technical Support",
  "Troubleshooting",
  "Performance Scans",
  "Google Webmaster",
  "Keyword analysis",
];

const arrWebsite = [
  "E-Commerce",
  "Custom Frameworks",
  "Progresive Web App",
  "Woo Commerce",
  "Custom Graphic Work",
  "Mobile Friendly",
  "Standard Pages",
  "Social Media Integration",
  "Customized Templates",
];

const arrApp = [
  "Native Programming",
  "Cross platform Native, Flutter, React Native",
  "Google Developer Set up",
  "App store connect set up",
  "Appstore Publishing",
  "Custom Graphic Work",
  "Mobile Friendly",
  "Appstore Publishing",
  "Custom Graphic Work",
  "Mobile Friendly",
];

const ui_ux = [
  "Mobile app UX and UI design services",
  "Web design services",
  "Sketching & Wireframes",
  "Dynamic prototype",
  "Cross-platform experiences design",
  "Animation prototype",
  "Graphical Interphase",
  "2x less time spent on development",
];

const onboardTxt = `Setting up your project. 

Unlike our monthly maintenance plans, no two project are the same.  Each project requires a full explanation of what your goals are and what the aesthetics of your web page or app will look like.  In order to meet all your needs we do require you to talk to one of our sales agent who will take as much information down and present it to the development team.  Once the development team has determined the work involved in your personalized project, we will then provide you with a quote which you can either accept or reject.  Our rates are very competitive and we are confident that you will accept our project cost.  Please click here to setup an account and submit your project. A sales agent will contact you within 24 hours.`;

export const PlanSectionV = () => {
  const history = useHistory();
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);
  const [show4, setshow4] = useState(false);
  const [pricingPicked, setPricingPick] = useState("");

  return (
    <div>
      <section id="plans">
        <div className="container my-5">
          {pricingPicked == "" && (
            <>
              <div>
                <h3 className="font-bold mt-5 mb-2 text-white">
                  Simple, transparent pricing
                </h3>
                <p>No contracts, No surprise fee</p>
              </div>
              <div className="row">
                {/* Management */}
                <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                  <div class="shadowCard mx-auto">
                    <p className="plan-txt">--seo</p>
                  </div>
                  <div class="shadowCard2 mx-auto">
                    <p className="plan-txt">--maintenance</p>
                  </div>
                  <div class="card mx-auto">
                    {/* <p className="">--frameworks</p> */}

                    <h3 class="header1">--Management</h3>
                    <div className="col-lg-12 col-md-12 col-sm-12 ruler" />

                    <div class="content">
                      <ul>
                        {arrayManagement.map((element) => (
                          <li className={"info"}>
                            <span className="mr-2"></span>
                            {element}
                          </li>
                        ))}
                      </ul>

                      <div className={"d-flex justify-content-center"}>
                        <button
                          class="btn btn-price"
                          onClick={() => {
                            setPricingPick(MANAGEMENT);
                          }}
                        >
                          See Pricing
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Web dev */}
                <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                  <div class="shadowCard mx-auto">
                    <p className="plan-txt">--reactjs</p>
                  </div>
                  <div class="shadowCard2 mx-auto">
                    <p className="plan-txt">--wordpress</p>
                  </div>
                  <div class="card mx-auto">
                    {/* <p className="">--frameworks</p> */}

                    <h3 class="header2">--Web Development</h3>
                    <div className="col-lg-12 col-md-12 col-sm-12 ruler" />

                    <div class="content">
                      <ul>
                        {arrWebsite.map((element) => (
                          <li className={"info"}>
                            <span className="mr-2"></span>
                            {element}
                          </li>
                        ))}
                      </ul>

                      <div className={"d-flex justify-content-center"}>
                        <button
                          class="btn btn-price"
                          onClick={() => {
                            setPricingPick(WEBSITES);

                            history.push({
                              pathname: "/auth",
                              state: { auth: "join", plan: WEBSITES },
                            });
                          }}
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* App Dev */}
                <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                  <div class="shadowCard mx-auto">
                    <p className="plan-txt">--flutter</p>
                  </div>
                  <div class="shadowCard2 mx-auto">
                    <p className="plan-txt">--swift</p>
                  </div>
                  <div class="card mx-auto">
                    {/* <p className="">--frameworks</p> */}

                    <h3 class="header3">--App Development</h3>
                    <div className="col-lg-12 col-md-12 col-sm-12 ruler" />

                    <div class="content">
                      <ul>
                        {arrApp.map((element) => (
                          <li className={"info"}>
                            <span className="mr-2"></span>
                            {element}
                          </li>
                        ))}
                      </ul>

                      <div className={"d-flex justify-content-center"}>
                        <button
                          class="btn btn-price"
                          onClick={() => {
                            setPricingPick(APPS);

                            history.push({
                              pathname: "/auth",
                              state: { auth: "join", plan: APPS },
                            });
                          }}
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* UI/UI Dev */}
                <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                  <div class="shadowCard mx-auto">
                    <p className="plan-txt">--xd</p>
                  </div>
                  <div class="shadowCard2 mx-auto">
                    <p className="plan-txt">--figma</p>
                  </div>
                  <div class="card mx-auto">
                    {/* <p className="">--frameworks</p> */}

                    <h3 class="header4">--UI/UX</h3>
                    <div className="col-lg-12 col-md-12 col-sm-12 ruler" />

                    <div class="content">
                      <ul>
                        {ui_ux.map((element) => (
                          <li className={"info"}>
                            <span className="mr-2"></span>
                            {element}
                          </li>
                        ))}
                      </ul>

                      <div className={"d-flex justify-content-center"}>
                        <button
                          class="btn btn-price"
                          onClick={() => {
                            setPricingPick(UI_UX);

                            history.push({
                              pathname: "/auth",
                              state: { auth: "join", plan: UI_UX },
                            });
                          }}
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {pricingPicked == MANAGEMENT && (
            <>
              <div className={"row align-items-center"}>
                <div className={"col-md-10 col-sm-8"}>
                  <h3 className="font-bold mt-5 mb-2 text-white">
                    Simple, transparent pricing
                  </h3>
                  <p>No contracts, No surprise fee</p>
                </div>
                <div
                  className={"click"}
                  onClick={() => {
                    setPricingPick("");
                  }}
                >
                  <span
                    className={"click"}
                    class="iconify"
                    data-icon="bytesize:close"
                    data-inline="false"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 col-6 my-3">
                  <div className="price-wrapper p-4">
                    <div>
                      <h5 className="font-bold">
                        PACKAGE A <br />
                        (Technical website audit)
                      </h5>
                      <div className="price">
                        <span className="tag">${PLAN_A}</span>
                        <span className="duration">/Month</span>
                      </div>
                      <div className="my-2">
                        Industry leading tools and strategies that boost website
                        traffic and conversions.
                      </div>
                      <ul>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Website crawl
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Broken links assessment and correction
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Inbound link assessment and cleanup
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Image tag assessment
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          XML sitemap creation
                        </li>
                        <li
                          className={`view-more ${show1 ? "d-none" : ""}`}
                          onClick={() => {
                            setshow4(false);
                            setshow2(false);
                            setshow1(true);
                          }}
                        >
                          View more
                        </li>
                      </ul>
                      <ul className={`more-content ${show1 ? "show" : ""}`}>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          URL restructuring
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Targeted Business Listings Optimization & Creation
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Load Time Optimization
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Plugin Maintenance
                        </li>
                      </ul>
                    </div>
                    <button
                      className="btn btn-plan my-4 mt-auto"
                      onClick={() => {
                        history.push({
                          pathname: "/auth",
                          state: { auth: "join", plan: "A", price: PLAN_A },
                        });
                      }}
                    >
                      Choose Plan
                    </button>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-6 my-3">
                  <div className="price-wrapper p-4">
                    <div>
                      <h5 className="font-bold">
                        PACKAGE B <br />
                        (Market and keyword analysis)
                      </h5>
                      <div className="price">
                        <span className="tag">${PLAN_B}</span>
                        <span className="duration">/Month</span>
                      </div>
                      <ul>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          PACKAGE A
                        </li>

                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Keyword analysis
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Keyword Optimization
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Content guidance
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Mobile Optimization
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Google My Business Setup & Optimization
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Basic Local Posts Setup
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Special Attributes Setup
                        </li>
                        <li
                          className={`view-more ${show2 ? "d-none" : ""}`}
                          onClick={() => {
                            setshow1(false);
                            setshow4(false);
                            setshow2(true);
                          }}
                        >
                          View more
                        </li>
                      </ul>
                      <ul className={`more-content ${show2 ? "show" : ""}`}>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Yelp & Local Listings Optimization
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Google Maps Optimization
                        </li>
                      </ul>
                    </div>
                    <button
                      className="btn btn-plan my-4 mt-auto"
                      onClick={() => {
                        history.push({
                          pathname: "/auth",
                          state: { auth: "join", plan: "B", price: PLAN_B },
                        });
                      }}
                    >
                      Choose Plan
                    </button>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-6 my-3">
                  <div className="price-wrapper p-4 bg-color">
                    <div>
                      <h5 className="font-bold">
                        PACKAGE C <br />
                        {"(SEO & Lead Generation)"}
                      </h5>
                      <div className="price">
                        <span className="tag">${PLAN_C}</span>
                        <span className="duration">/Month</span>
                      </div>
                      <ul>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          {"PACKAGE A&B"}
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Optimize current pages
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Add additional pages
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Taxonomy optimization
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Mobile optimization
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Optimizing videos for search engines
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Optimizing images for search engines (Image SEO)
                        </li>
                      </ul>
                    </div>
                    <button
                      className="btn btn-plan my-4 mt-auto"
                      onClick={() => {
                        history.push({
                          pathname: "/auth",
                          state: { auth: "join", plan: "C", price: PLAN_C },
                        });
                      }}
                    >
                      Choose Plan
                    </button>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-6 my-3">
                  <div className="price-wrapper p-4">
                    <div>
                      <h5 className="font-bold">(PAID MARKETING)</h5>
                      <div className="price">
                        <span className="tagC">Custom Price</span>
                        {/* <span className="duration">/Month</span> */}
                      </div>
                      <div className="my-2">
                        For most of the buiness that want to optimize web
                        queries
                      </div>
                      <ul>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Drive traffic through paid search
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Reduce search engine marketing costs and CPC
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Dominate search pages
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Drive leads and/or sales
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Copy creation
                        </li>
                        <li
                          className={`view-more ${show4 ? "d-none" : ""}`}
                          onClick={() => {
                            setshow1(false);
                            setshow2(false);
                            setshow4(true);
                          }}
                        >
                          View more
                        </li>
                      </ul>
                      <ul className={`more-content ${show4 ? "show" : ""}`}>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Achieve synergies between your paid search and natural
                          (organic) search programs
                        </li>

                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          UTM tracking for Adcenter campaigns
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Competitive analysis
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Keyword research and development
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Google Analytics tags
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Bid management
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Site selection for Google content network
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Campaign structure
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Landing page selection
                        </li>
                        <li>
                          <span className="mr-2">
                            <span
                              class="iconify"
                              data-icon="bx:bxs-check-shield"
                              data-inline="false"
                            ></span>
                          </span>
                          Conversion tracking set-up
                        </li>
                      </ul>
                    </div>
                    <button
                      className="btn btn-plan my-4 mt-auto"
                      onClick={() => {
                        history.push(`/contact`);
                      }}
                    >
                      Contact Sales
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          {pricingPicked == APPS ||
            pricingPicked == WEBSITES ||
            (pricingPicked == UI_UX && <></>)}
        </div>
      </section>
    </div>
  );
};
