import React from "react";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import pattern2 from "../assets/img/bg-pattern2.svg";
import pattern1 from "../assets/img/bg-pattern1.svg";
import { Sidebar } from "../components/Sidebar";

export const Contact = () => {
  return (
    <div className="signup-wrapper">
      <section class="hidden">
        <Navbar whiteBg />
        <Sidebar whiteBg />
        <div class="p-r form-wrapper">
          <div class="row">
            <div class="col-lg-2 col-sm-12 mt-auto sm-dnone">
              <img src={pattern1} alt="" class="w-100" />
            </div>
            <div class="col-lg-1 col-sm-12"></div>
            <div class="col-lg-6 col-sm-12">
              <div class="container text-center">
                <div class="mt-5">
                  <p class="font-bold font50">Contact Us</p>

                  <p class="font-light font18">
                    Do you have any questions, requests, feedback or an idea you
                    would like to share with us?
                  </p>
                </div>
                <div class="row my-5">
                  <div class="col-lg-9 col-sm-12 mx-auto">
                    <form class="px-4 py-5">
                      <div class="p-r my-4">
                        <input
                          type="email"
                          class="common-input form-control"
                          placeholder="Name"
                        />
                        <span class="input-icon outline">
                          <span
                            class="iconify"
                            data-icon="ant-design:user-outlined"
                            data-inline="false"
                          ></span>
                        </span>
                      </div>
                      <div class="p-r my-4">
                        <input
                          type="email"
                          class="common-input form-control"
                          placeholder="Phone"
                        />
                        <span class="input-icon outline">
                          <span
                            class="iconify"
                            data-icon="cil:phone"
                            data-inline="false"
                          ></span>
                        </span>
                      </div>
                      <div class="p-r my-4">
                        <input
                          type="email"
                          class="common-input form-control"
                          placeholder="Email"
                        />
                        <span class="input-icon">
                          <span
                            class="iconify"
                            data-icon="bi:at"
                            data-inline="false"
                          ></span>
                        </span>
                      </div>
                      <div class="p-r my-4">
                        <textarea
                          class="form-control common-input"
                          rows="3"
                          placeholder="How can we help?"
                        ></textarea>
                        <span class="input-icon">
                          <span
                            class="iconify"
                            data-icon="akar-icons:question"
                            data-inline="false"
                          ></span>
                        </span>
                      </div>
                      <div class="my-4">
                        <button class="btn btn-orange">Send</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-1 col-sm-12"></div>
            <div class="col-lg-2 col-sm-12 mb-auto mt-5 sm-dnone">
              <img src={pattern2} alt="" class="w-100" />
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-sm-12 text-center my-3">
              <p class="font25 font-bold text-black">We are here</p>
              <p class="font18 text-grey">
                Ontario Canada <br />
                Email:{" "}
                <a href="mailto:support@solnary.com" className="text-inherit">
                  {" "}
                  support@solnary.com
                </a>
              </p>
            </div>
            <div class="col-lg-6 text-center col-sm-12 my-3">
              <p class="font25 font-bold text-black">Working Hours</p>
              <p class="font18 text-grey">
                Monday - Friday:8:00-18:30 Hrs <br />
              </p>
              <a href=""></a>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};
