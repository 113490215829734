import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { Sidebar } from "../../components/Sidebar";
import { STATUS } from "../../config/constant";
import fire from "../../config/fire";
import { getuserData } from "../../helpers/fireRequests";
import { DashboardHeader } from "./dashboard-header";
import "./styles.scss";

export const Dashboard = () => {
  const [projects, setProjects] = useState([]);
  const history = useHistory();

  const [newProject, setNewProject] = useState([]);
  const [inProgess, setInProgress] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [isAdmin, setAdmin] = useState(false);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    var uid = fire.firebase.auth()?.currentUser?.uid;

    if (uid) getProjects();
    else {
      setTimeout(() => {
        getProjects();
      }, 2000);
    }
  }, []);

  const checkAdmin = (uid) => {
    getuserData(uid)
      .then((res) => {
        console.log(res);
        setAdmin(res?.isAdmin);
      })
      .catch((error) => {
        console.log(error, "getUser");
      });
  };

  const getProjects = () => {
    var uid = fire.firebase.auth()?.currentUser?.uid;

    if (!uid) {
      console.log(uid, "MO UID");

      history.push("/");
      toast("Error getting your data. Please try signing in again");
      return;
    }
    checkAdmin(uid);

    fire.firebase
      .firestore()
      .collection("projects")
      .where("userId", "==", uid)
      .get()
      .then(async function (querySnapshot) {
        if (querySnapshot.empty) {
          // toast("No Projects found");
          return;
        }

        let itemsProcessed = 0;

        let projectItem = [];
        let newItems = [];
        let progressItems = [];
        let completedItems = [];

        querySnapshot.forEach((doc, id, array) => {
          var itemVal = doc.data();

          itemVal.id = doc.id;
          itemVal.createdAt = getDateFromStamp(itemVal.createdAt);

          if (itemVal.status == STATUS.NEW) {
            newItems.push(itemVal);
          }

          if (itemVal.status == STATUS.IN_PROGRESS) {
            progressItems.push(itemVal);
          }

          if (itemVal.status == STATUS.COMPLETED) {
            completedItems.push(itemVal);
          }

          projectItem.push(itemVal);

          itemsProcessed++;

          if (itemsProcessed === array?.length) {
          }
        });

        // toast("Ir");
        setNewProject(newItems);
        setInProgress(progressItems);
        setCompleted(completedItems);

        projectItem.sort((a, b) =>
          b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
        );

        setProjects(projectItem);

        setloading(false);
      })
      .catch((error) => {
        toast("Error getting order data");
        console.log("Error getting user data: ", error);
      });
  };

  const getDateFromStamp = (timestamp) => {
    // const date = new Date(timestamp).toDateString();
    return new Date(parseInt(timestamp)).toDateString();
  };

  const viewProject = (element) => {
    // console.log(element);
    history.push({
      pathname: `/project/${element.business_name}`,
      state: { project: element },
    });
  };

  return (
    <div className="dashboard-wrapper">
      {/* <div className="body"> */}
      <ToastContainer />

      <section className="body-container">
        <Navbar whiteBg />
        <Sidebar whiteBg />
        <div className="container py-5">
          <DashboardHeader
            data={{
              projects: projects,
              newProject,
              inProgess,
              completed,
              isAdmin,
            }}
          />
        </div>
      </section>

      {projects.length > 0 && (
        <section id={"projects"} className={"container projects"}>
          <h3 className={"mb-0 mt-4"}>PROJECTS</h3>
          <div className={"d-flex flex-wrap"}>
            {projects.map((element) => (
              <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                <div className="shadowCard2 mx-auto">
                  <p className="plan-txt">
                    -- {element.projectCategory || "maintenance"}
                  </p>
                </div>
                <div class="card mx-auto">
                  {/* <p className="">--frameworks</p> */}

                  <h3 class="header1">--{element.business_name}</h3>
                  <div className={"d-flex"}>
                    <h6 className={"status"}>{element.status}</h6>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 ruler" />

                  <div class="content">
                    {/* <ul> */}
                    <li className={"info"}>
                      {/* <span className="mr-2"></span> */}
                      Admin - {element.name}
                    </li>
                    <li className={"info"}>
                      {/* <span className="mr-2"></span> */}
                      Timeline - {element.deadline}
                    </li>
                    {/* </ul> */}

                    <div className={"d-flex justify-content-center"}>
                      <button
                        class="btn btn-price"
                        onClick={() => {
                          viewProject(element);
                        }}
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}

      <Footer className="mt-auto" />
      {/* </div> */}
    </div>
  );
};
