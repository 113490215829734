// import firebase from "firebase/app";
import firebase from "firebase";

const config = {
  apiKey: "AIzaSyDcGChgcMg0_r6WVlliTmXWv8DygNUIYRo",
  authDomain: "solnary-2c1ba.firebaseapp.com",
  projectId: "solnary-2c1ba",
  storageBucket: "solnary-2c1ba.appspot.com",
  messagingSenderId: "584250821992",
  appId: "1:584250821992:web:0bc0d166eba4e1ff739add",
  measurementId: "G-CW4C2QZ56L",
};

const fire = firebase.initializeApp(config);
firebase.analytics();

export default {
  fire,
  config,
  firebase,
  isBackend: false,
  id_token: "solnary",
};
