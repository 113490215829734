import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "./styles.scss";
import { RadialChart } from "react-vis";
import appConfig from "../../config/fire";
import { useHistory } from "react-router";

const STATUS = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
};
export const DashboardHeader = (props) => {
  const { projects, newProject, inProgess, completed, isAdmin } = props.data;

  // const [projects, setProjects] = useState([]);

  // const [notStarted, setNotStated] = useState([]);
  // const [inProgess, setInProgress] = useState([]);
  // const [completed, setCompleted] = useState([]);

  const [loading, setloading] = useState(false);

  useEffect(() => {
    // getProjects();
  }, []);

  const history = useHistory();

  const getDateFromStamp = (timestamp) => {
    // const date = new Date(timestamp).toDateString();
    return new Date(parseInt(timestamp)).toDateString();
  };

  return (
    <div className="row mt-5 align-items-center">
      <ToastContainer />

      <div className="col-lg-12 col-md-12 mb-3">
        <div className="row">
          <div className="col-lg-4 col-sm-6  mb-3">
            <div className="card2 p-4">
              <div>
                <p className="font12 font-bold mb-0">PROJECTS</p>
                <p className="font12 font-bold">COMPLETED</p>
              </div>
              <div className="mt-auto font32">{completed.length}</div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mb-3">
            <div className="card2 p-4">
              <div>
                <p className="font12 font-bold mb-0">UPCOMING </p>
                <p className="font12 font-bold">PROJECTS</p>
              </div>
              <div className="mt-auto font32">{newProject.length}</div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6  mb-3">
            <div className="card2 p-4">
              <div>
                <p className="font12 font-bold mb-0">ONGOING </p>
                <p className="font12 font-bold">PROJECTS</p>
              </div>
              <div className="mt-auto font32">{inProgess.length}</div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6  mb-3">
            <div className="card2 p-4">
              <div>
                <p className="font12 font-bold mb-0">TOTAL</p>
                <p className="font12 font-bold">PROJECT</p>
              </div>
              <div className="mt-auto font32">
                {completed.length + newProject.length + inProgess.length}
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-sm-6  mb-3"
            onClick={() => {
              history.push("/questionaire");
            }}
          >
            <div className="card3 p-4 click">
              <div>
                <p className="font15White font-bold mb-0">CREATE</p>
                <p className="font15White font-bold">NEW PROJECT</p>
              </div>
              <div class="go-corner" href="#">
                <div class="go-arrow">→</div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-6  mb-3"
            onClick={() => {
              history.push("/subscription");
            }}
          >
            <div className="card3 p-4 click">
              <div>
                <p className="font15White font-bold mb-0">NEW</p>
                <p className="font15White font-bold">SUBSCRIPTION</p>
              </div>
              <div class="go-corner" href="#">
                <div class="go-arrow">→</div>
              </div>
            </div>
          </div>

          {isAdmin && (
            <div
              className="col-lg-4 col-sm-6  mb-3"
              onClick={() => {
                history.push("/admin");
              }}
            >
              <div className="card3 p-4 click">
                <div>
                  <p className="font15White font-bold mb-0">ADMIN</p>
                  <p className="font15White font-bold">DASHBOARD</p>
                </div>
                <div class="go-corner" href="#">
                  <div class="go-arrow">→</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
