import React from "react";
import ReactGlobe from "react-globe";

export const HeaderTest = () => {
  return (
    <div className="test-header-wrapper">
      <ReactGlobe />
    </div>
  );
};
