import firebase from "firebase";

const getuserData = (uid) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          //   let row = {};
          var itemVal = doc.data();
          resolve(itemVal);
        } else {
          resolve({});
          console.log("User data not found");
          // reject("User data not found");
        }
      })
      .catch((error) => {
        reject("Error getting user data: " + error);
      });
  });
};

const getOrderData = (orderID) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("orders")
      .doc(orderID)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          //   let row = {};
          var itemVal = doc.data();
          resolve(itemVal);
        } else {
          resolve({});
          console.log("Product data not found");
          // reject("User data not found");
        }
      })
      .catch((error) => {
        reject("Error getting product data: " + error);
      });
  });
};

const getProductData = (productID) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("products")
      // .where("productID", "==", productID)
      .doc(productID)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          //   let row = {};
          var itemVal = doc.data();
          resolve(itemVal);
        } else {
          resolve({});
          console.log("Product data not found");
          // reject("User data not found");
        }
      })
      .catch((error) => {
        reject("Error getting product data: " + error);
      });
  });
};

export { getuserData, getOrderData, getProductData };
