import React from "react";
import { Footer } from "../components/FooterV";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";
import app from "../assets/img/app.png";
import { Link, useHistory } from "react-router-dom";
import tech from "../assets/img/tech.jpg";
import play from "../assets/img/ic-play.png";
import design from "../assets/img/design.jpeg";
import sol1 from "../assets/img/sol_port.png";
import sol2 from "../assets/img/sol2.png";
import { PlanSection } from "./PlanSection";
import { SliderSection } from "./SliderSection";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Lottie from "lottie-react";
import groovyWalkAnimation from "../../src/assets/lottie/developer.json";
import android from "../assets/img/android.png";
import react from "../assets/img/react.png";
import js from "../assets/img/js.png";
import apple from "../assets/img/apple.png";
import java from "../assets/img/java.png";
import python from "../assets/img/python.png";
import plus from "../assets/img/plus.png";
import fire from "../assets/img/fire.png";
import database from "../assets/img/database.png";
import { ServicesSectionV } from "./ServiceSectionV";
import { PlanSectionV } from "./PlanSectionV";
import ScrollButton from "../components/ScrollButton";

export const Main = () => {
  const history = useHistory();

  const carouselOptions = {
    // breakpoint from 0 up
    0: {
      items: 1.5,
    },
    // breakpoint from 480 up
    480: {
      items: 2.5,
    },
    // breakpoint from 768 up
    768: {
      items: 3.5,
    },
  };

  return (
    <div className="main-wrapper">
      <div className="body">
        <section className="main-container">
          <header>
            <Navbar />
            <Sidebar />
            <div className="container main-head">
              <div className="row">
                <div className="col-lg-8 col-md-7 col-12 my-auto">
                  <div className="header-text">
                    Integrate your idea <br /> with{" "}
                    <span className="solnary">Solnary</span>
                    <br /> innovative <span className="skill">skills</span>
                  </div>
                  <p>
                    Welcome to Solnary, your number one source for all web and
                    app-related projects. We're dedicated to providing you the
                    very best of websites, apps, and maintenance packages with
                    an emphasis on innovation, security, quality.
                  </p>

                  <div
                    className={
                      "container-input sub-actions mb-4 align-items-center d-flex"
                    }
                  >
                    <a href="/#plans">
                      <button className="btn px-5">{"Get Started"}</button>
                    </a>
                    <div
                      data-toggle="modal"
                      data-target="#videoModal"
                      className="intro ml-4 click d-flex align-items-center"
                    >
                      <img src={play} className={"mr-2"} alt="Play Icon" />
                      <p className={"mb-0"}>Intro Video</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 col-12 my-auto text-center lottieAnim">
                  <Lottie animationData={groovyWalkAnimation} />
                </div>
              </div>
            </div>
          </header>

          <section className="container">
            <div className={"row"}>
              <div className="col-lg-7 col-md-9 col-sm-10 mx-auto mb-4 mt-4">
                <div className={"brands justify-content-center flex-wrap"}>
                  <div className={"d-flex align-items-center m-2"}>
                    <img src={react} alt="" className="" />
                    <p>ReactJs</p>
                  </div>
                  <div className={"d-flex align-items-center m-2"}>
                    <img src={js} alt="" className="" />
                    <p>JavaScript</p>
                  </div>
                  <div className={"d-flex align-items-center m-2"}>
                    <img src={android} alt="" className="" />
                    <p>Android</p>
                  </div>
                  <div className={"d-flex align-items-center m-2"}>
                    <img src={apple} alt="" className="" />
                    <p>Apple</p>
                  </div>
                  <div className={"d-flex align-items-center m-2"}>
                    <img src={java} alt="" className="" />
                    <p>Java</p>
                  </div>
                  <div className={"d-flex align-items-center m-2"}>
                    <img src={python} alt="" className="" />
                    <p>Python</p>
                  </div>
                  {/* <div className={"d-flex align-items-center m-2"}>
                    <img src={html} alt="" className="" />
                    <p>Html</p>
                  </div> */}
                  <div className={"d-flex align-items-center m-2"}>
                    <img src={database} alt="" className="" />
                    <p>Database</p>
                  </div>
                  <div className={"d-flex align-items-center m-2"}>
                    <img src={fire} alt="" className="" />
                    <p>Firebase</p>
                  </div>

                  <div className={"d-flex align-items-center m-2"}>
                    <img src={plus} alt="" className="" />
                    <p>And More..</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="" id="about">
            <div className="inner-about">
              <div className="col-lg-10 col-md-9 col-sm-10">
                <h3 className="font-bold title">
                  With a team of experts that have the skills to build high-end
                  projects.
                </h3>
                <p className="mb-0">
                  Solnary plans to grow with you and offer all the services you
                  need to help your company grow to its fullest potential.
                </p>
                <Link to="/contact">
                  <button class="btn btn-alt mt-4">
                    Contact Sales{" "}
                    <span
                      class="iconify mr-2"
                      data-icon="bytesize:arrow-right"
                      data-inline="false"
                    />
                  </button>
                </Link>
              </div>

              <div className={"my-5"}>
                <p className={"mb-0 ml-3"}>{"Innovation. Security. Quality"}</p>

                <div className="col-lg-7 col-md-7 col-sm-7 ruler" />

                <h6 className={"my-4 col-lg-9 col-md-10 col-sm-7"}>
                  Solnary is a team comprised of skilled individuals throughout
                  the world with our headquarter located in London Ontario,
                  Canada.
                  <br />
                  Our goal is to deliver projects that are innovative, secure,
                  and met with a high stander of quality to our clients. Solnary
                  plans to grow with you and offer all the services you need to
                  help your company grow to its fullest potential. If you are a
                  startup and looking for a consultant we are here to help you
                  and guide you in the right direction. We have a team of
                  experts that have the skills to build high-end projects. We
                  offer express and quality web development, app development,
                  creative content writing, and competitive SEO services to make
                  sure that you can win in the digital field.
                </h6>
              </div>
            </div>
          </section>

          <section className="" id={"portfolio"}>
            <div className={"photoshopped-boxes"}>
              <h3 className="font-bold title my-4">Recent Projects</h3>
              <div class="masonry">
                <div class="brick">
                  <div class="brick-overlay"></div>
                  <img src={sol2} alt="" className="brick-image" />
                  <div class="content-details fadeIn-bottom">
                    <h2 class="content-title">SobrDD</h2>
                    {/* <p class="content-text">This is a short description</p> */}
                  </div>
                </div>
                <div class="brick">
                  <div class="brick-overlay"></div>
                  <img src={sol1} alt="" className="brick-image" />
                  <div class="content-details fadeIn-bottom">
                    <h2 class="content-title">Wolfpack</h2>
                    {/* <p class="content-text">This is a short description</p> */}
                  </div>{" "}
                </div>
                {/* <div class="brick">
                  <img src={sol1} alt="" className="w-100" />
                </div>
                <div class="brick">
                  <img src={sol2} alt="" className="w-100" />
                </div> */}
              </div>
            </div>
          </section>

          <section id={"tech"}>
            <div className="tech row">
              <div className="col-md-6 col-12 mb-4">
                <div class="shadowCard mx-auto">
                  <p className="">--var</p>
                </div>
                <div class="shadowCard2 mx-auto">
                  <p className="">--const</p>
                </div>
                <div class="card mx-auto">
                  {/* <p className="">--frameworks</p> */}

                  <h3 class="header">--FRAMEWORKS</h3>
                  <div className="col-lg-12 col-md-12 col-sm-12 ruler" />

                  <div class="content">
                    <p class="info">
                      - Any MVC( framework) <br /> - ReactJs <br /> - Angular{" "}
                      <br /> - Vue <br /> - React Native <br /> - Android Studio{" "}
                      <br /> - XCode <br /> - Laravel <br /> - Codeigniter,
                      Zend, <br /> - CakePhp, <br /> - Yii. <br /> - CMS
                      <br /> - WordPress <br /> - Shopify <br /> - OpenCart{" "}
                      <br /> - Wix <br /> - Drupa. <br /> - And More ...
                    </p>
                    <Link to="/contact">
                      <button class="btn-alt btn">Custom Request</button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-12 mb-4">
                <h3 className="title">OUR MISSION</h3>
                <p className="font-tech">
                  Our mission at Solnary is to create unique products for each
                  of our clients with creativity, innovation, and imagination.
                </p>

                <div className="my-4">
                  <h3 className="title">CAPABILITIES</h3>
                  <p className="font-tech">
                    We can deliver any kind of web based application. We have
                    completed more than 500 projects for different companies and
                    clients. <br />
                    Domains we work in
                  </p>
                  <ul>
                    <li>Business Websites</li>
                    <li>Portfolios</li>
                    <li>E-commerce custom or CMS</li>
                    <li>Web applications based on customer requirements</li>
                    <li> Online Stores </li>
                    <li>Company websites and web applications</li>
                    <li>Real Estate Web Projects</li>
                    <li>
                      Web apps for Clinics with online booking functionalities
                    </li>
                    <li>Grocery stores etc.</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <ServicesSectionV />
          <PlanSectionV />
          <section class="container my-5">
            <div class="row">
              <div class="col-lg-10 col-sm-12 mx-auto my-5 text-center">
                <p class="font48 font-bold text-black alt-font">
                  Ready to have more impactful conversations?
                </p>
                <p class="font20 text-grey">
                  Be up and running in just a few minutes !
                </p>
                <Link to="/contact">
                  <button class="btn btn-orange">Contact Sales</button>
                </Link>
              </div>
            </div>
          </section>
        </section>

        <ScrollButton />

        <Footer className="mt-auto" />
      </div>

      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="videoModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="videoModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="d-flex justify-content-end px-4 pt-3">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/8is6AdqU6jw"
                title="Solnary"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              {/* <video
                src="https://youtu.be/8is6AdqU6jw"
                // poster={tech}
                autoPlay
                className="w-100 br-10 tech-img"
                style={{ height: "400px" }}
                controls
              ></video> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
