const MANAGEMENT = "MANAGEMENT",
  WEBSITES = "WEBSITES",
  APPS = "APPS",
  UI_UX = "UI_UX",
  PLAN_A = "149",
  PLAN_B = "299",
  PLAN_C = "499";

export const STATUS = {
  NEW: "NEW",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
};

export { MANAGEMENT, WEBSITES, APPS, UI_UX, PLAN_A, PLAN_B, PLAN_C };
