import React from "react";
import { Footer } from "../components/FooterV";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";

export const Faq = () => {
  return (
    <div className="faq-wrapper">
      <header>
        <Sidebar whiteBg />
        <Navbar whiteBg />
        <div className="container">
          <h2 className={"faq-header"}>FAQ</h2>
          <p>Frequently asked questions.</p>
        </div>
      </header>
      <section className="container pb-5">
        <h4 className="font-bold">What is Solnary?</h4>
        <p className="mb-4">
          Solnary is a company that is based in Canada. We are specialized in
          website and phone application developments. Here at Solnary, we work
          on a simple portfolio website to complex react native apps.{" "}
        </p>
        <h4 className="font-bold">What type of apps does Solnary develop?</h4>
        <p className="lc mb-4">
          Android App Development
          <br />
          iOS App Development <br />
          React Natice <br />
          Flutter
          <br />
          Web Apps
          {/* <br />
          NodeJS
          <br />
          Phyton{" "} */}
        </p>
        <h4 className="font-bold">
          What type of website does Solnary develop?
        </h4>
        <p className="mb-4">
          Business Website
          <br />
          E-Commerce Custom OR CMS
          <br />
          ReactJs/Angular/Vue Web applications
          <br />
          Wordpress
          <br />
          Shopify
          <br />
          Portfolios
          <br />
          Real Estate Web Project
          <br />
          Social Media
          <br />
          e.t.c
        </p>

        <h4 className="font-bold">
          Is Solnary specialized in website and app maintenance?
        </h4>
        <p className="mb-4">
          Solnary maintains websites and apps by provides a different set of
          packages. Customers can choose from a simple and affordable package to
          custom packages that are designed to meet clients' specific needs.
        </p>
        <h4 className="font-bold">Does Solnary have SEO packages?</h4>
        <p className="mb-4">
          Solnary offers Search Engine Optimization (SEO) packages, please see
          what we offer under our service section!
        </p>
        <h4 className="font-bold">Is there is a custom project available?</h4>
        <p className="mb-4">
          Yes, the Solnary development team will meet your custom project
          requirements and do an outstanding job. Please contact one of our
          representatives via the "Contact Us" section.
        </p>
        <h4 className="font-bold">How to contact Solnary?</h4>
        <p className="mb-4">
          You can contact Solnary via email For Sale: Sales@solnary.com
          <br />
          For general inquiry: Info@solnary.com
          <br />
          In addition, you can simply go to the "Contact Us" section and submit
          your inquiry directly!
        </p>
        <h4 className="font-bold">Why do I have to sign up?</h4>
        <p className="mb-4">
          Solnary requires every customer to set up an account using our website
          www.Solnary.com. Customers will be able to see the progress of their
          project and communicate with our development team directly.
        </p>
        <h4 className="font-bold">
          Can I see the progress of my project from the Solnary website?
        </h4>
        <p className="mb-4">
          Yes, Customers will be able to see the progress of their project and
          communicate with our development team directly.
        </p>

        <h4 className="font-bold">Can I request changes from the dashboard?</h4>
        <p className="mb-4">
          Yes, Customers will be able to see the progress of their project and
          communicate with our development team directly, while also requesting
          updates and changes. How long my projects take? <br />
          Solnary takes each project case by case. Depending on the volume of
          the projects it could take from two weeks to months. However, Solnary
          will give each customer an estimate of time after all the information
          needed to begin the project!
        </p>
        <h4 className="font-bold">Why do I need monthly packages?</h4>
        <p className="mb-4">
          Solnary offer monthly packages to ease up the workload from customers.
          Solnary understands and has the expertise to keep a website
          functioning as long as possible. Maintenance is critical for that
          reason. Solnary offers a different set of packages including a custom
          package to enhance each client's needs and ensure their success by
          helping them from web and app aspects.
        </p>
      </section>
      <Footer />
    </div>
  );
};
