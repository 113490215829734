import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import logo from "../assets/img/logo.png";
import appConfig from "../config/fire";

export const Navbar = (props) => {
  const { whiteBg } = props;
  const [isLoggedIn, setisLoggedIn] = useState(false);
  appConfig.firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      setisLoggedIn(true);
    } else {
      setisLoggedIn(false);
    }
  });
  const history = useHistory();
  window.onscroll = () => scrollFunction();

  const scrollFunction = () => {
    if (window.scrollY > 150) {
      document.querySelector(".navbar")?.classList.add("fixed-top");
    } else {
      document.querySelector(".navbar")?.classList.remove("fixed-top");
    }
  };

  const auth = (auth) => {
    history.push({ pathname: "/auth", state: { auth } });
  };
  return (
    <div className={whiteBg ? "navbar navbarWh" : "navbar"}>
      <div className="px-4 d-flex justify-content-between align-items-center w-100">
        <div className="nav-logo">
          <NavLink to="/">
            <img src={logo} alt="" />
          </NavLink>
        </div>
        <div className="d-flex align-items-center">
          <NavLink className="navlink" to="/" activeClassName="active">
            Home
          </NavLink>

          {isLoggedIn && (
            <div
              className="navlink click"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              Dashboard
            </div>
          )}

          <a className="navlink" href="/#about">
            About
          </a>
          <a className="navlink" href="/#services">
            Services
          </a>
          <a className="navlink" href="/#plans">
            Plans
          </a>

          <NavLink className="navlink" to="/faq" activeClassName="active">
            FAQ
          </NavLink>

          <NavLink className="navlink" to="/contact">
            <button className="btn btn-contact">Contact Us</button>
          </NavLink>

          {!isLoggedIn ? (
            <div className="ml-4">
              <a href="#" className="navlink signup">
                <button className="btn signup" onClick={() => auth("join")}>
                  {" "}
                  Sign Up
                </button>
              </a>
              <a
                href="#"
                className="navlink login"
                onClick={() => auth("login")}
              >
                Login
              </a>
            </div>
          ) : (
            <div className="ml-4">
              <a href="#" className="navlink signup">
                <button
                  className="btn signup"
                  onClick={() => {
                    appConfig.firebase.auth().signOut();
                    history.push("/");
                  }}
                >
                  {" "}
                  Logout
                </button>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
