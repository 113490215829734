import React, { useEffect, useState } from "react";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";
import logo from "../assets/img/logo.png";
import { Footer } from "../components/Footer";
import { useHistory, useLocation } from "react-router";
import { getuserData } from "../helpers/fireRequests";
import appConfig from "../config/fire";

export const Receipt = () => {
  const history = useHistory();
  const location = useLocation();

  const project = location.state.project;
  let [user, setUser] = useState({ name: "", email: "" });
  let [loading, setLoading] = useState(true);

  const printOut = () => {
    window.print();
  };

  useEffect(() => {
    var uid = appConfig.firebase.auth().currentUser.uid;
    getuserData(uid)
      .then((respone) => {
        setLoading(false);
        setUser(respone);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <section
      style={{
        backgroundColor: "#EFF0F2",
      }}
    >
      <Navbar />
      <Sidebar />
      <section
        className="container"
        style={{ marginTop: "12vh", marginBottom: "12vh" }}
      >
        <div className="mb-4 text-right">
          <button
            className="btn btn-download mr-3"
            onClick={() => {
              history.push({
                pathname: `/stripe`,
                state: { plan: "C", price: "499" },
              });
            }}
          >
            Make Payment
          </button>
          <button className="btn btn-download" onClick={printOut}>
            Download
          </button>
        </div>
        <section className="receipt-wrapper">
          <div className="stamp">
            {/* <!-- You can change the respective status by changing the class and status name dynamically here, the css is readily available for all the statuses. NB: make sure it is written in small letters --> */}
            <span className="badge badge-draft"> draft </span>
          </div>
          <section className="d-flex justify-content-between flex-wrap">
            <div>
              <h1 style={{ fontSize: "34px " }}>{project.projectName}</h1>
              <div style={{ marginBottom: "20px", display: "flex" }}>
                <div style={{ marginRight: "20px" }}>
                  <p style={{ fontSize: "14px", color: "#999999" }}>
                    Invoice Number
                  </p>
                  <p style={{ fontSize: "14px", color: "#484848" }}>
                    INV/2020/0015
                  </p>
                </div>
                <div>
                  <p style={{ fontSize: "14px", color: "#999999" }}>
                    Invoice Number
                  </p>
                  <p style={{ fontSize: "14px", color: "#484848" }}>
                    INV/2020/0015
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{ textAlign: "right", fontSize: "14px", color: "#484848" }}
            >
              <img
                src={logo}
                alt=""
                style={{
                  width: "104px",
                  height: "63px",
                  objectFit: "contain",
                }}
              />
              <p>Ontario</p>
              <p>London, CA</p>
              <p>+1-23456789</p>
              <p>support@solnary.com</p>
            </div>
          </section>
          <hr style={{ border: "1px solid #e5e5e5" }} />
          <section
            className="d-flex flex-wrap justify-content-between"
            style={{
              fontSize: "14px",
            }}
          >
            <div>
              <p style={{ color: "#999999", marginBottom: "14px" }}>
                Billed to
              </p>
              <p style={{ color: "#484848", fontWeight: "500" }}>{user.name}</p>
              <p style={{ color: "#484848", fontWeight: "300" }}>
                {user.email}
              </p>
              {/* <p style={{ color: "#484848", fontWeight: "300" }}>
                {user.address}
              </p> */}
            </div>
            {/* <div>
              <p style={{ color: "#999999", marginBottom: "14px" }}>Student</p>
              <p style={{ color: "#484848", fontWeight: "500" }}>James David</p>
              <p style={{ color: "#484848", fontWeight: "300" }}>JSS 2</p>
            </div> */}
          </section>
          <section
            class="table-responsive"
            style={{
              backgroundColor: "#f5fafd",
              marginTop: "35px",
              // padding: "10px 20px",
            }}
          >
            <table class="table text-nowrap">
              <tr>
                <th>Items</th>
                <th>Discount</th>
                <th>Tax</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Amount</th>
              </tr>
              <tr>
                <td>UI/UX</td>
                <td>5%</td>
                <td>5%</td>
                <td>1</td>
                <td>$ 1,200.00</td>
                <td>$ 1,200.00</td>
              </tr>
              <tr>
                <td>Front End Development</td>
                <td>5%</td>
                <td>5%</td>
                <td>1</td>
                <td>$ 2,000.00</td>
                <td>$ 2,000.00</td>
              </tr>

              <tr style={{ borderTop: "solid 1px #484848" }}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ fontWeight: "500" }}>Sub Total:</td>
                <td>$ 3,200.00</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ fontWeight: "500" }}>Total Tax:</td>
                <td>$ 320.00</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ fontWeight: "500" }}>Total Discount:</td>
                <td>$ 320.00</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ fontWeight: "bolder" }}>Total:</td>
                <td style={{ fontWeight: "bolder" }}>$ 3,200.00</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ fontWeight: "bold" }}>Amount Due:</td>
                <td style={{ fontWeight: "bold" }}>$ 3,200.00</td>
              </tr>
            </table>
          </section>
          <section style={{ marginTop: "80px" }}>
            <p
              style={{
                marginBottom: "20px",
                fontWeight: "500",
                color: "#8b9eb0",
              }}
            >
              Notes
            </p>
            <ul
              style={{
                paddingLeft: "15px",
                color: "#979797",
                fontWeight: "400",
              }}
            >
              <li>All Fees must be paid on or before {project.createdAt}</li>
            </ul>
          </section>
          <hr style={{ marginTop: "80px", border: "1px solid #f3f3f3" }} />
          <p style={{ color: "#979797", fontSize: "14px" }}>
            All Fees must be paid on or before the described deadlines to either
            using solnary in-app payment network.
          </p>
        </section>
      </section>
      <Footer className="mt-auto" />
    </section>
  );
};
